import moment from "moment";
import { loginSuccess } from "../../ducks/auth.duck";
import { fetchCurrentUser } from "../../ducks/user.duck";
import { onWhatsappTransaction } from "../../util/api";
import { denormalisedResponseEntities } from "../../util/data";
import { DEAL_LISTING_TYPE, SERVICE_LISTING_TYPE } from "../../util/types";
import { deliveryOptions } from "../CartPage/CartPage.duck";

export const FETCH_BOOKING_REQUEST = 'app/SearchPage/FETCH_BOOKING_REQUEST';
export const FETCH_BOOKING_SUCCESS = 'app/SearchPage/FETCH_BOOKING_SUCCESS';
export const FETCH_BOOKING_ERROR = 'app/SearchPage/FETCH_BOOKING_ERROR';

const initialState = {
  checkoutData: null,
  fetchBookingInProgress: false,
  fetchBookingError: null,
};

const WhatsappTransactionsPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case FETCH_BOOKING_REQUEST:
      return {
        ...state,
        checkoutData: null,
        fetchBookingInProgress: true,
        searchListingsError: null,
      };
    case FETCH_BOOKING_SUCCESS:
      return {
        ...state,
        checkoutData: payload,
        fetchBookingInProgress: false,
      };
    case FETCH_BOOKING_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, fetchBookingInProgress: false, fetchBookingError: payload };
    default:
      return state;
  }
};

export default WhatsappTransactionsPageReducer;

export const fetchBookingRequest = () => ({
  type: FETCH_BOOKING_REQUEST,
});

export const fetchBookingSuccess = response => ({
  type: FETCH_BOOKING_SUCCESS,
  payload: response,
});

export const fetchBookingError = e => ({
  type: FETCH_BOOKING_ERROR,
  payload: e,
});


export const loadData = (params, search, config) => (dispatch, getState, sdk) => {
  dispatch(fetchBookingRequest());

  try {
    const { transactionId } = params;
    if (transactionId) {
      return onWhatsappTransaction({ transactionId })
        .then(async (res) => {
          await dispatch(loginSuccess());
          await dispatch(fetchCurrentUser());
          const { service_id, staff_id, booking_start_time, booking_end_time } = res?.data || {};

          const listingResponse = await sdk.listings.show({ id: service_id, include: ['author', 'images'] })
          const listing = denormalisedResponseEntities(listingResponse)?.[0];
          const listingId = listing?.id?.uuid;

          const { title: listingTitle, publicData } = listing?.attributes || '';
          const { location: businessLocation, listingType, deal_items, dealType } = publicData || {};
          const listingImage = listing?.images?.[0]?.attributes?.variants?.['listing-card']?.url || null;

          const author = listing?.author || {};
          const authorId = author?.id?.uuid;
          const { business_name } = author?.attributes?.profile?.publicData || {};

          // set initial data for checkout page for service or deal
          const checkoutData = listingType === SERVICE_LISTING_TYPE ?
            {
              listing,
              cartListings: [listing],
              cartAuthorId: authorId,
              merchantDetails: {
                name: business_name,
                location: businessLocation,
              },
              orderData: {
                cart: {
                  [authorId]: {
                    [listingId]: {
                      bookingStart: booking_start_time,
                      bookingEnd: booking_end_time,
                      count: 1,
                      deliveryMethod: deliveryOptions?.INSTORE_VISIT,
                      image: listingImage,
                      staffId: staff_id,
                      title: listingTitle,
                      type: SERVICE_LISTING_TYPE,
                    }
                  }
                },
              }
            }
            : {
              listing,
              cartListings: [listing],
              cartAuthorId: authorId,
              merchantDetails: {
                name: business_name,
                location: businessLocation,
              },
              orderData: {
                cart: {
                  [authorId]: {
                    [listingId]: {
                      count: 1,
                      image: listingImage,
                      title: listingTitle,
                      type: DEAL_LISTING_TYPE,
                      deliveryMethod: deliveryOptions?.INSTORE_VISIT,
                      // dealAddedAt: 1740746875,
                      // deal_end_time: 1741953889,
                      dealType: dealType,
                      dealListingIds: [
                        {
                          id: deal_items?.[0],
                          type: "service",
                          bookingStart: booking_start_time,
                          bookingEnd: booking_end_time,
                          staffId: staff_id,
                        }
                      ],
                    }
                  }
                },
                bookingDates: {
                  bookingStart: moment().startOf('day').toISOString(),
                  bookingEnd: moment().add(1, 'day').startOf('day').toISOString()
                }
              }
            }
          return dispatch(fetchBookingSuccess(checkoutData));
        })
    }
  } catch (error) {
    dispatch(fetchBookingError(error));
    throw e;
  }
};