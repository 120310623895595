import { onFetchUserByEmail, onUpdateUserPasswordHash } from '../../util/api';
import { storableError } from '../../util/errors';

const WHATSAPP = "whatsapp";

// ================ Action types ================ //

export const RESET_PASSWORD_REQUEST = 'app/PasswordResetPage/RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'app/PasswordResetPage/RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_ERROR = 'app/PasswordResetPage/RESET_PASSWORD_ERROR';

// ================ Reducer ================ //

const initialState = {
  resetPasswordInProgress: false,
  resetPasswordError: null,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case RESET_PASSWORD_REQUEST:
      return { ...state, resetPasswordInProgress: true, resetPasswordError: null };
    case RESET_PASSWORD_SUCCESS:
      return { ...state, resetPasswordInProgress: false };
    case RESET_PASSWORD_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, resetPasswordInProgress: false, resetPasswordError: payload };
    default:
      return state;
  }
}

// ================ Action creators ================ //

export const resetPasswordRequest = () => ({ type: RESET_PASSWORD_REQUEST });

export const resetPasswordSuccess = () => ({ type: RESET_PASSWORD_SUCCESS });

export const resetPasswordError = e => ({
  type: RESET_PASSWORD_ERROR,
  error: true,
  payload: e,
});

// ================ Thunks ================ //

export const resetPassword = (email, passwordResetToken, newPassword) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(resetPasswordRequest());
  const params = { email, passwordResetToken, newPassword };
  return sdk.passwordReset
    .reset(params)
    .then(() => {
      return onFetchUserByEmail({ email })
        .then(async (res) => {
          const user = res?.data || {};

          const userId = user?.id?.uuid;
          const { email, profile } = user?.attributes || {};
          const { createdFrom } = profile?.publicData || {};

          const userFromWhatsapp = createdFrom === WHATSAPP;

          if (userFromWhatsapp) {
            await onUpdateUserPasswordHash({
              userId: userId,
              password: newPassword,
              email: email
            });
          }

          return dispatch(resetPasswordSuccess());
        })
        .catch((e) => {
          // Handle the error in the user fetch/update process
          dispatch(resetPasswordError(storableError(e)));
        });
    })
    .catch((e) => {
      // Handle error in the password reset process
      dispatch(resetPasswordError(storableError(e)));
    });
};
