import pick from 'lodash/pick';

import { isArray } from 'lodash';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { currentUserShowSuccess, fetchCurrentUser, fetchCurrentUserHasOrdersSuccess } from '../../ducks/user.duck';
import { getProcess, isBookingProcessAlias } from '../../transactions/transaction';
import { onGetDetailedListingRatings, onGetListingReviews, onGetStaffReviews, transactionLineItems } from '../../util/api';
import { denormalisedResponseEntities } from '../../util/data';
import { findNextBoundary, getStartOf, monthIdString } from '../../util/dates';
import { storableError } from '../../util/errors';
import * as log from '../../util/log';
import { createImageVariantConfig, types as sdkTypes } from '../../util/sdkLoader';
import { DEAL_LISTING_TYPE, PRODUCT_LISTING_TYPE, SERVICE_LISTING_TYPE } from '../../util/types';
import {
  LISTING_PAGE_DRAFT_VARIANT,
  LISTING_PAGE_PENDING_APPROVAL_VARIANT,
  parse,
} from '../../util/urlHelpers';
import { categoriesListingFn } from '../EditListingPage/EditListingPage.duck';
import { getAlgoliaRecordById } from '../ViewBusinessPage/ViewBusinessPage.duck';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/ListingPage/SET_INITIAL_VALUES';

export const SHOW_LISTING_REQUEST = 'app/ListingPage/SHOW_LISTING_REQUEST';
export const SHOW_LISTING_ERROR = 'app/ListingPage/SHOW_LISTING_ERROR';

export const FETCH_REVIEWS_REQUEST = 'app/ListingPage/FETCH_REVIEWS_REQUEST';
export const FETCH_REVIEWS_SUCCESS = 'app/ListingPage/FETCH_REVIEWS_SUCCESS';
export const FETCH_REVIEWS_ERROR = 'app/ListingPage/FETCH_REVIEWS_ERROR';

export const FETCH_RATINGS_REQUEST = 'app/ListingPage/FETCH_RATINGS_REQUEST';
export const FETCH_RATINGS_SUCCESS = 'app/ListingPage/FETCH_RATINGS_SUCCESS';
export const FETCH_RATINGS_ERROR = 'app/ListingPage/FETCH_RATINGS_ERROR';

export const FETCH_TIME_SLOTS_REQUEST = 'app/ListingPage/FETCH_TIME_SLOTS_REQUEST';
export const FETCH_TIME_SLOTS_SUCCESS = 'app/ListingPage/FETCH_TIME_SLOTS_SUCCESS';
export const FETCH_TIME_SLOTS_ERROR = 'app/ListingPage/FETCH_TIME_SLOTS_ERROR';

export const FETCH_ITEM_TIME_SLOTS_SUCCESS = 'app/ListingPage/FETCH_ITEM_TIME_SLOTS_SUCCESS';
export const FETCH_ITEM_TIME_SLOTS_REQUEST = 'app/ListingPage/FETCH_ITEM_TIME_SLOTS_REQUEST';
export const FETCH_ITEM_TIME_SLOTS_ERROR = 'app/ListingPage/FETCH_ITEM_TIME_SLOTS_ERROR';

export const FETCH_LINE_ITEMS_REQUEST = 'app/ListingPage/FETCH_LINE_ITEMS_REQUEST';
export const FETCH_LINE_ITEMS_SUCCESS = 'app/ListingPage/FETCH_LINE_ITEMS_SUCCESS';
export const FETCH_LINE_ITEMS_ERROR = 'app/ListingPage/FETCH_LINE_ITEMS_ERROR';

export const SEND_INQUIRY_REQUEST = 'app/ListingPage/SEND_INQUIRY_REQUEST';
export const SEND_INQUIRY_SUCCESS = 'app/ListingPage/SEND_INQUIRY_SUCCESS';
export const SEND_INQUIRY_ERROR = 'app/ListingPage/SEND_INQUIRY_ERROR';

export const FETCH_STAFF_LISTINGS_REQUEST = 'app/ListingPage/FETCH_STAFF_LISTINGS_REQUEST';
export const FETCH_STAFF_LISTINGS_SUCCESS = 'app/ListingPage/FETCH_STAFF_LISTINGS_SUCCESS';
export const FETCH_STAFF_LISTINGS_ERROR = 'app/ListingPage/FETCH_STAFF_LISTINGS_ERROR';

export const FETCH_PROFILE_LISTING_REQUEST = 'app/ListingPage/FETCH_PROFILE_LISTING_REQUEST';
export const FETCH_PROFILE_LISTING_SUCCESS = 'app/ListingPage/FETCH_PROFILE_LISTING_SUCCESS';
export const FETCH_PROFILE_LISTING_ERROR = 'app/ListingPage/FETCH_PROFILE_LISTING_ERROR';

export const FETCH_DEAL_ITEMS_REQUEST = 'app/ListingPage/FETCH_DEAL_ITEMS_REQUEST';
export const FETCH_DEAL_ITEMS_SUCCESS = 'app/ListingPage/FETCH_DEAL_ITEMS_SUCCESS';
export const FETCH_DEAL_ITEMS_ERROR = 'app/ListingPage/FETCH_DEAL_ITEMS_ERROR';

export const FETCH_RELATED_ITEMS_REQUEST = 'app/ListingPage/FETCH_RELATED_ITEMS_REQUEST';
export const FETCH_RELATED_ITEMS_SUCCESS = 'app/ListingPage/FETCH_RELATED_ITEMS_SUCCESS';
export const FETCH_RELATED_ITEMS_ERROR = 'app/ListingPage/FETCH_RELATED_ITEMS_ERROR';

// ================ Reducer ================ //
const initialState = {
  id: null,
  showListingError: null,
  reviews: [],
  fetchReviewsInProgress: false,
  fetchReviewsError: null,

  ratings: {},
  fetchRatingsInProgress: false,
  fetchRatingsError: null,

  monthlyTimeSlots: {
    // '2022-03': {
    //   timeSlots: [],
    //   fetchTimeSlotsError: null,
    //   fetchTimeSlotsInProgress: null,
    // },
  },
  lineItems: null,
  fetchLineItemsInProgress: false,
  fetchLineItemsError: null,
  sendInquiryInProgress: false,
  sendInquiryError: null,
  inquiryModalOpenForListingId: null,
  fetchStaffListingsError: null,
  fetchStaffListingsInProgress: false,
  currentPageStaffIds: [],
  fetchProfileListingError: null,
  fetchProfileListingInProgress: false,
  businessProfileListing: {},
  currentPageDealItemIds: [],
  fetchDealItemsError: null,
  fetchDealItemsInProgress: false,
  currentPageRelatedItemIds: [],
  fetchRelatedItemsError: null,
  fetchRelatedItemsInProgress: false,
  itemMonthlyTimeSlots: {
    // '2022-03': {
    //   timeSlots: [],
    //   fetchTimeSlotsError: null,
    //   fetchTimeSlotsInProgress: null,
    // },
  }
};

const resultIds = data => {
  const listings = isArray(data.data) ? data.data : [data.data];
  return listings
    .filter(l => !l.attributes.deleted && l.attributes.state === 'published')
    .map(l => l.id);
};

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SHOW_LISTING_REQUEST:
      return { ...state, id: payload.id, showListingError: null };
    case SHOW_LISTING_ERROR:
      return { ...state, showListingError: payload };

    case FETCH_REVIEWS_REQUEST:
      return { ...state, fetchReviewsError: null, fetchReviewsInProgress: true };
    case FETCH_REVIEWS_SUCCESS:
      return { ...state, reviews: payload, fetchReviewsInProgress: false };
    case FETCH_REVIEWS_ERROR:
      return { ...state, fetchReviewsError: payload, reviews: [], fetchReviewsInProgress: false };

    case FETCH_RATINGS_REQUEST:
      return { ...state, fetchRatingsError: null, fetchRatingsInProgress: true };
    case FETCH_RATINGS_SUCCESS:
      return { ...state, ratings: payload, fetchRatingsInProgress: false };
    case FETCH_RATINGS_ERROR:
      return { ...state, fetchRatingsError: payload, ratings: {}, fetchRatingsInProgress: false };

    case FETCH_TIME_SLOTS_REQUEST: {
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [payload]: {
          ...state.monthlyTimeSlots[payload],
          fetchTimeSlotsError: null,
          fetchTimeSlotsInProgress: true,
        },
      };
      return { ...state, monthlyTimeSlots };
    }
    case FETCH_ITEM_TIME_SLOTS_REQUEST: {
      const itemMonthlyTimeSlots = {
        ...state.itemMonthlyTimeSlots,
        [payload]: {
          ...state.itemMonthlyTimeSlots[payload],
          fetchTimeSlotsError: null,
          fetchTimeSlotsInProgress: true,
        },
      };
      return { ...state, itemMonthlyTimeSlots };
    }
    case FETCH_TIME_SLOTS_SUCCESS: {
      const monthId = payload.monthId;
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [monthId]: {
          ...state.monthlyTimeSlots[monthId],
          fetchTimeSlotsInProgress: false,
          timeSlots: payload.timeSlots,
        },
      };
      return { ...state, monthlyTimeSlots };
    }
    case FETCH_ITEM_TIME_SLOTS_SUCCESS: {
      const monthId = payload.monthId;
      const itemMonthlyTimeSlots = {
        ...state.itemMonthlyTimeSlots,
        [monthId]: {
          ...state.itemMonthlyTimeSlots[monthId],
          fetchTimeSlotsInProgress: false,
          timeSlots: payload.timeSlots,
        },
      };
      return { ...state, itemMonthlyTimeSlots };
    }
    case FETCH_TIME_SLOTS_ERROR: {
      const monthId = payload.monthId;
      const monthlyTimeSlots = {
        ...state.monthlyTimeSlots,
        [monthId]: {
          ...state.monthlyTimeSlots[monthId],
          fetchTimeSlotsInProgress: false,
          fetchTimeSlotsError: payload.error,
        },
      };
      return { ...state, monthlyTimeSlots };
    }
    case FETCH_ITEM_TIME_SLOTS_ERROR: {
      const monthId = payload.monthId;
      const itemMonthlyTimeSlots = {
        ...state.itemMonthlyTimeSlots,
        [monthId]: {
          ...state.itemMonthlyTimeSlots[monthId],
          fetchTimeSlotsInProgress: false,
          fetchTimeSlotsError: payload.error,
        },
      };
      return { ...state, itemMonthlyTimeSlots };
    }

    // For Staff listings
    case FETCH_STAFF_LISTINGS_REQUEST:
      return {
        ...state,
        currentPageStaffIds: [],
        fetchStaffListingsInProgress: true,
        fetchStaffListingsError: null,
      };
    case FETCH_STAFF_LISTINGS_SUCCESS:
      return {
        ...state,
        fetchStaffListingsInProgress: false,
        currentPageStaffIds: resultIds(payload.data),
      };
    case FETCH_STAFF_LISTINGS_ERROR:
      return { ...state, fetchStaffListingsInProgress: false, fetchStaffListingsError: payload };
    /////////////////////////

    // For Profile listings
    case FETCH_PROFILE_LISTING_REQUEST:
      return {
        ...state,
        businessProfileListing: {},
        fetchProfileListingInProgress: true,
        fetchProfileListingError: null,
      };
    case FETCH_PROFILE_LISTING_SUCCESS:
      return {
        ...state,
        fetchProfileListingInProgress: false,
        businessProfileListing: payload,
      };
    case FETCH_PROFILE_LISTING_ERROR:
      return { ...state, fetchProfileListingInProgress: false, fetchProfileListingError: payload };
    /////////////////////////

    // For listings(deal_items) in Deal
    case FETCH_DEAL_ITEMS_REQUEST:
      return {
        ...state,
        currentPageDealItemIds: [],
        fetchDealItemsInProgress: true,
        fetchDealItemsError: null,
      };
    case FETCH_DEAL_ITEMS_SUCCESS:
      return {
        ...state,
        fetchDealItemsInProgress: false,
        currentPageDealItemIds: resultIds(payload.data),
      };
    case FETCH_DEAL_ITEMS_ERROR:
      return { ...state, fetchDealItemsInProgress: false, fetchDealItemsError: payload };
    /////////////////////////

    // For Related items
    case FETCH_RELATED_ITEMS_REQUEST:
      return {
        ...state,
        currentPageRelatedItemIds: [],
        fetchRelatedItemsInProgress: true,
        fetchRelatedItemsError: null,
      };
    case FETCH_RELATED_ITEMS_SUCCESS:
      return {
        ...state,
        fetchRelatedItemsInProgress: false,
        currentPageRelatedItemIds: resultIds(payload.data),
      };
    case FETCH_RELATED_ITEMS_ERROR:
      return { ...state, fetchRelatedItemsInProgress: false, fetchRelatedItemsError: payload };
    /////////////////////////

    case FETCH_LINE_ITEMS_REQUEST:
      return { ...state, fetchLineItemsInProgress: true, fetchLineItemsError: null };
    case FETCH_LINE_ITEMS_SUCCESS:
      return { ...state, fetchLineItemsInProgress: false, lineItems: payload };
    case FETCH_LINE_ITEMS_ERROR:
      return { ...state, fetchLineItemsInProgress: false, fetchLineItemsError: payload };

    case SEND_INQUIRY_REQUEST:
      return { ...state, sendInquiryInProgress: true, sendInquiryError: null };
    case SEND_INQUIRY_SUCCESS:
      return { ...state, sendInquiryInProgress: false };
    case SEND_INQUIRY_ERROR:
      return { ...state, sendInquiryInProgress: false, sendInquiryError: payload };

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

export const showListingRequest = id => ({
  type: SHOW_LISTING_REQUEST,
  payload: { id },
});

export const showListingError = e => ({
  type: SHOW_LISTING_ERROR,
  error: true,
  payload: e,
});

export const fetchReviewsRequest = () => ({ type: FETCH_REVIEWS_REQUEST });
export const fetchReviewsSuccess = reviews => ({ type: FETCH_REVIEWS_SUCCESS, payload: reviews });
export const fetchReviewsError = error => ({
  type: FETCH_REVIEWS_ERROR,
  error: true,
  payload: error,
});

export const fetchRatingsRequest = () => ({ type: FETCH_RATINGS_REQUEST });
export const fetchRatingsSuccess = ratings => ({ type: FETCH_RATINGS_SUCCESS, payload: ratings });
export const fetchRatingsError = error => ({
  type: FETCH_RATINGS_ERROR,
  error: true,
  payload: error,
});

export const fetchTimeSlotsRequest = monthId => ({
  type: FETCH_TIME_SLOTS_REQUEST,
  payload: monthId,
});
export const fetchTimeSlotsSuccess = (monthId, timeSlots) => ({
  type: FETCH_TIME_SLOTS_SUCCESS,
  payload: { timeSlots, monthId },
});
export const fetchTimeSlotsError = (monthId, error) => ({
  type: FETCH_TIME_SLOTS_ERROR,
  error: true,
  payload: { monthId, error },
});

export const fetchItemTimeSlotsRequest = monthId => ({
  type: FETCH_ITEM_TIME_SLOTS_REQUEST,
  payload: monthId,
});
export const fetchItemTimeSlotsSuccess = (monthId, timeSlots) => ({
  type: FETCH_ITEM_TIME_SLOTS_SUCCESS,
  payload: { timeSlots, monthId },
});
export const fetchItemTimeSlotsError = (monthId, error) => ({
  type: FETCH_ITEM_TIME_SLOTS_ERROR,
  error: true,
  payload: { monthId, error },
});


export const fetchLineItemsRequest = () => ({ type: FETCH_LINE_ITEMS_REQUEST });
export const fetchLineItemsSuccess = lineItems => ({
  type: FETCH_LINE_ITEMS_SUCCESS,
  payload: lineItems,
});
export const fetchLineItemsError = error => ({
  type: FETCH_LINE_ITEMS_ERROR,
  error: true,
  payload: error,
});

// Action creators for staff listings
export const fetchStaffListingsRequest = () => ({ type: FETCH_STAFF_LISTINGS_REQUEST });
export const fetchStaffListingsSuccess = response => ({
  type: FETCH_STAFF_LISTINGS_SUCCESS,
  payload: response,
});
export const fetchStaffListingsError = error => ({
  type: FETCH_STAFF_LISTINGS_ERROR,
  error: true,
  payload: error,
});

// Action creators for Profile listing
export const fetchProfileListingRequest = () => ({ type: FETCH_PROFILE_LISTING_REQUEST });
export const fetchProfileListingSuccess = response => ({
  type: FETCH_PROFILE_LISTING_SUCCESS,
  payload: response,
});
export const fetchProfileListingError = error => ({
  type: FETCH_PROFILE_LISTING_ERROR,
  error: true,
  payload: error,
});

// Action creators for listings in Deal (deal_items)
export const fetchDealItemsRequest = () => ({ type: FETCH_DEAL_ITEMS_REQUEST });
export const fetchDealItemsSuccess = response => ({
  type: FETCH_DEAL_ITEMS_SUCCESS,
  payload: response,
});
export const fetchDealItemsError = error => ({
  type: FETCH_DEAL_ITEMS_ERROR,
  error: true,
  payload: error,
});

// Action creators for Related Items
export const fetchRelatedItemsRequest = () => ({ type: FETCH_RELATED_ITEMS_REQUEST });
export const fetchRelatedItemsSuccess = response => ({
  type: FETCH_RELATED_ITEMS_SUCCESS,
  payload: response,
});
export const fetchRelatedItemsError = error => ({
  type: FETCH_RELATED_ITEMS_ERROR,
  error: true,
  payload: error,
});


export const sendInquiryRequest = () => ({ type: SEND_INQUIRY_REQUEST });
export const sendInquirySuccess = () => ({ type: SEND_INQUIRY_SUCCESS });
export const sendInquiryError = e => ({ type: SEND_INQUIRY_ERROR, error: true, payload: e });

/**
* This is a simplified version of the toggleCart function
*/
// Add or remove items from cart
export const toggleCart = (payload) => (dispatch, getState, sdk) => {
  const { listingId, authorId, increment = 1, extraParams } = payload || {};

  const currentUser = getState().user.currentUser;
  const cart = currentUser.attributes.profile.privateData?.cart || [];

  // Cart as object with author ids as keys
  let newCart = getNewCart(cart, authorId, listingId, increment, extraParams);

  sdk.currentUser
    .updateProfile(
      {
        privateData: {
          cart: newCart,
        },
      },
      { expand: true }
    )
    .then(resp => {
      const entities = denormalisedResponseEntities(resp);
      if (entities.length !== 1) {
        throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
      }
      const currentUser = entities[0];

      // Update current user in state.user.currentUser through user.duck.js
      dispatch(currentUserShowSuccess(currentUser));
    })
    .catch(e => {
      console.log('e', e)
    });
};

const listingIsInCart = (cart, authorId, listingId) => {
  if (!cart || !cart[authorId]) {
    return false;
  }

  return Object.keys(cart[authorId]).includes(listingId);
};


const getNewCart = (cart, authorId, listingId, increment, extraParams) => {
  const authorInCart = Object.keys(cart).includes(authorId);
  const isListingInCart = listingIsInCart(cart, authorId, listingId);

  const newCount = ((cart[authorId] && cart[authorId][listingId]?.count) || 0) + increment;

  // Increment an existing listing
  if (authorInCart && isListingInCart && newCount > 0) {
    return {
      ...cart,
      [authorId]: {
        ...cart[authorId],
        [listingId]: {
          count: newCount,
          ...extraParams
        },
      },
    };
    // Remove an existing listing from cart
  } else if (authorInCart && isListingInCart && newCount <= 0) {
    const newCart = { ...cart };
    delete newCart[authorId][listingId];

    // If the listing was the author's last one, remove the author as well
    if (Object.keys(newCart[authorId]).length == 0) {
      delete newCart[authorId];
    }

    return newCart;
    // Add new listing to an existing author
  } else if (authorInCart && !isListingInCart) {
    return {
      ...cart,
      [authorId]: {
        ...cart[authorId],
        [listingId]: {
          count: increment,
          ...extraParams
        },
      },
    };
    // Add new listing and a new author
  } else {
    return {
      ...cart,
      [authorId]: {
        [listingId]: {
          count: increment,
          ...extraParams
        },
      },
    };
  }
};

// ================ Thunks ================ //

export const showListing = (listingId, config, isOwn = false) => (dispatch, getState, sdk) => {
  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;

  dispatch(showListingRequest(listingId));
  dispatch(fetchCurrentUser());
  const params = {
    id: listingId,
    include: ['author', 'author.profileImage', 'images', 'currentStock'],
    'fields.image': [
      // Scaled variants for large images
      'variants.scaled-small',
      'variants.scaled-medium',
      'variants.scaled-large',
      'variants.scaled-xlarge',

      // Cropped variants for listing thumbnail images
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
      `variants.${variantPrefix}-4x`,
      `variants.${variantPrefix}-6x`,

      // Social media
      'variants.facebook',
      'variants.twitter',

      // Avatars
      'variants.square-small',
      'variants.square-small2x',
    ],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-4x`, 1600, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-6x`, 2400, aspectRatio),
  };

  const show = isOwn ? sdk.ownListings.show(params) : sdk.listings.show(params);

  return show
    .then(data => {
      // const serviceNum = data?.data?.data?.attributes?.publicData?.serviceDuration;
      // console.log("serviceNum", serviceNum);
      // if (serviceNum) { data.data.data.attributes.publicData.serviceDuration = String(serviceNum) }

      const listingFields = config?.listing?.listingFields;
      const sanitizeConfig = { listingFields };
      dispatch(addMarketplaceEntities(data, sanitizeConfig));
      return data;
    })
    .catch(e => {
      dispatch(showListingError(storableError(e)));
    });
};

export const fetchReviews = (listingId, listingType) => async (dispatch, getState, sdk) => {
  try {
    dispatch(fetchReviewsRequest());
    dispatch(fetchRatingsRequest());

    // Validate listingId to prevent unnecessary API calls if invalid
    if (!listingId?.uuid && !listingId) {
      throw new Error('Invalid listingId');
    }

    const isStaff = listingType === "staff";
    const updatedListingType = isStaff ? "stafId" : "listingId";
    const getReviews = isStaff ? onGetStaffReviews : onGetListingReviews;

    const reviewsPromise = getReviews({ [updatedListingType]: listingId?.uuid || listingId, page: 1, limit: 10 });
    const ratingsPromise = onGetDetailedListingRatings({ id: listingId?.uuid || listingId });

    const [reviewsResponse, ratingsResponse] = await Promise.all([reviewsPromise, ratingsPromise]);

    const reviews = reviewsResponse?.reviews || [];
    const ratings = ratingsResponse || {};

    // Dispatch reviews and ratings success actions
    dispatch(fetchReviewsSuccess(reviews));
    dispatch(fetchRatingsSuccess(ratings));

  } catch (err) {
    // Log error and dispatch error actions for both reviews and ratings
    console.error('Error fetching reviews or ratings:', err);

    // Dispatch error actions with the formatted error for reviews and ratings
    dispatch(fetchReviewsError(storableError(err)));
    dispatch(fetchRatingsError(storableError(err)));
  }
};

const timeSlotsRequest = params => (dispatch, getState, sdk) => {
  return sdk.timeslots.query(params).then(response => {
    return denormalisedResponseEntities(response);
  });
};

export const fetchTimeSlots = (listingId, start, end, timeZone) => (dispatch, getState, sdk) => {
  const monthId = monthIdString(start, timeZone);

  dispatch(fetchTimeSlotsRequest(monthId));

  // The maximum pagination page size for timeSlots is 500
  const extraParams = {
    perPage: 500,
    page: 1,
  };

  return dispatch(timeSlotsRequest({ listingId, start, end, ...extraParams }))
    .then(timeSlots => {
      dispatch(fetchTimeSlotsSuccess(monthId, timeSlots));
    })
    .catch(e => {
      dispatch(fetchTimeSlotsError(monthId, storableError(e)));
    });
};

export const sendInquiry = (listing, message) => (dispatch, getState, sdk) => {
  dispatch(sendInquiryRequest());
  const processAlias = listing?.attributes?.publicData?.transactionProcessAlias;
  if (!processAlias) {
    const error = new Error('No transaction process attached to listing');
    log.error(error, 'listing-process-missing', {
      listingId: listing?.id?.uuid,
    });
    dispatch(sendInquiryError(storableError(error)));
    return Promise.reject(error);
  }

  const listingId = listing?.id;
  const [processName, alias] = processAlias.split('/');
  const transitions = getProcess(processName)?.transitions;

  const bodyParams = {
    transition: transitions.INQUIRE,
    processAlias,
    params: { listingId },
  };
  return sdk.transactions
    .initiate(bodyParams)
    .then(response => {
      const transactionId = response.data.data.id;

      // Send the message to the created transaction
      return sdk.messages.send({ transactionId, content: message }).then(() => {
        dispatch(sendInquirySuccess());
        dispatch(fetchCurrentUserHasOrdersSuccess(true));
        return transactionId;
      });
    })
    .catch(e => {
      dispatch(sendInquiryError(storableError(e)));
      throw e;
    });
};

// Helper function for loadData call.
const fetchMonthlyTimeSlots = (dispatch, listing) => {
  const hasWindow = typeof window !== 'undefined';
  const attributes = listing.attributes;
  // Listing could be ownListing entity too, so we just check if attributes key exists
  const hasTimeZone =
    attributes && attributes.availabilityPlan && attributes.availabilityPlan.timezone;

  // Fetch time-zones on client side only.
  if (hasWindow && listing.id && hasTimeZone) {
    const tz = listing.attributes.availabilityPlan.timezone;
    const unitType = attributes?.publicData?.unitType;
    const timeUnit = unitType === 'hour' ? 'hour' : 'day';
    const nextBoundary = findNextBoundary(new Date(), timeUnit, tz);

    const nextMonth = getStartOf(nextBoundary, 'month', tz, 1, 'months');
    const nextAfterNextMonth = getStartOf(nextMonth, 'month', tz, 1, 'months');

    return Promise.all([
      dispatch(fetchTimeSlots(listing.id, nextBoundary, nextMonth, tz)),
      dispatch(fetchTimeSlots(listing.id, nextMonth, nextAfterNextMonth, tz)),
    ]);
  }

  // By default return an empty array
  return Promise.all([]);
};

export const fetchTransactionLineItems = ({ orderData, listingId, isOwnListing }) => dispatch => {
  dispatch(fetchLineItemsRequest());
  transactionLineItems({ orderData, listingId, isOwnListing })
    .then(response => {
      const lineItems = response.data;
      dispatch(fetchLineItemsSuccess(lineItems));
    })
    .catch(e => {
      dispatch(fetchLineItemsError(storableError(e)));
      log.error(e, 'fetching-line-items-failed', {
        listingId: listingId.uuid,
        orderData,
      });
    });
};

// Fetch Staff listings as per selected time slots
export const fetchStaffListings = ({ listingId, profileListingId, config, start, end }) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(fetchStaffListingsRequest());

  sdk.listings
    .show({
      id: profileListingId,
    })
    .then(response => {
      const businessProfile = response?.data?.data;
      const staffIds = businessProfile?.attributes?.publicData?.staffId || [];

      sdk.listings
        .query(
          {
            ids: staffIds,
            availability: 'time-full',
            start,
            end,
            pub_serviceIds: `has_any:${listingId?.uuid}`,
            include: ['images'],
            'fields.image': ['variants.square-small', 'variants.square-small2x'],
          },
          {
            expand: true,
          }
        )
        .then(response => {
          const listingFields = config?.listing?.listingFields;
          const sanitizeConfig = { listingFields };
          dispatch(addMarketplaceEntities(response, sanitizeConfig));
          dispatch(fetchStaffListingsSuccess(response));
        });
    })
    .catch(e => {
      dispatch(fetchStaffListingsError(storableError(e)));
    });
};

export const fetchDealItemTimeSlots = (listingId, start, end, timeZone) => (dispatch, getState, sdk) => {
  const monthId = monthIdString(start, timeZone);

  dispatch(fetchItemTimeSlotsRequest(monthId));

  // The maximum pagination page size for timeSlots is 500
  const extraParams = {
    perPage: 500,
    page: 1,
  };

  return dispatch(timeSlotsRequest({ listingId, start, end, ...extraParams }))
    .then(timeSlots => {
      dispatch(fetchItemTimeSlotsSuccess(monthId, timeSlots));
    })
    .catch(e => {
      dispatch(fetchItemTimeSlotsError(monthId, storableError(e)));
    });
}

export const fetchDealItemMonthlyTimeSlots = (listing) => (dispatch, getState, sdk) => {
  const hasWindow = typeof window !== 'undefined';
  const attributes = listing.attributes;
  // Listing could be ownListing entity too, so we just check if attributes key exists
  const hasTimeZone =
    attributes && attributes.availabilityPlan && attributes.availabilityPlan.timezone;

  // Fetch time-zones on client side only.
  if (hasWindow && listing.id && hasTimeZone) {
    const tz = listing.attributes.availabilityPlan.timezone;
    const unitType = attributes?.publicData?.unitType;
    const timeUnit = unitType === 'hour' ? 'hour' : 'day';
    const nextBoundary = findNextBoundary(new Date(), timeUnit, tz);

    const nextMonth = getStartOf(nextBoundary, 'month', tz, 1, 'months');
    const nextAfterNextMonth = getStartOf(nextMonth, 'month', tz, 1, 'months');

    return Promise.all([
      dispatch(fetchDealItemTimeSlots(listing.id, nextBoundary, nextMonth, tz)),
      dispatch(fetchDealItemTimeSlots(listing.id, nextMonth, nextAfterNextMonth, tz)),
    ]);
  }

  // By default return an empty array
  return Promise.all([]);
}

export const loadData = (params, search, config) => (dispatch, getState, sdk) => {
  const listingId = new UUID(params.id);

  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  const { bookingLink } = queryParams || {};
  const isBookingLink = bookingLink === true;

  // Clear old line-items
  dispatch(setInitialValues({ lineItems: null }));

  const ownListingVariants = [LISTING_PAGE_DRAFT_VARIANT, LISTING_PAGE_PENDING_APPROVAL_VARIANT];
  if (ownListingVariants.includes(params.variant)) {
    return dispatch(showListing(listingId, config, true));
  }

  return Promise.allSettled([
    dispatch(showListing(listingId, config)),
  ]).then(response => {
    dispatch(categoriesListingFn());
    const listing = response[0]?.value?.data?.data; // Ensure proper data extraction
    // const listingId = listing?.id?.uuid;

    const { transactionProcessAlias = '', profileListingId, deal_items, categoryLevel2, listingType } = listing?.attributes?.publicData || {};
    const isDeal = listingType === DEAL_LISTING_TYPE;
    const updatedListingId = (isDeal && deal_items?.[0]) ? deal_items[0] : listingId;
    dispatch(fetchReviews(updatedListingId, listingType));

    if (isBookingProcessAlias(transactionProcessAlias)) {
      // Fetch timeSlots.
      // This can happen parallel to loadData.
      // We are not interested to return them from loadData call.
      fetchMonthlyTimeSlots(dispatch, listing);
    }
    const updatedDealItems = deal_items && deal_items?.length ?
      deal_items?.map(deal => deal?.value ? deal?.value : deal)
      : null;

    const {
      aspectWidth = 1,
      aspectHeight = 1,
      variantPrefix = 'listing-card',
    } = config.layout.listingImage;

    const aspectRatio = aspectHeight / aspectWidth;

    if (updatedDealItems) {
      dispatch(fetchDealItemsRequest());

      sdk.listings.query({
        ids: updatedDealItems,
        include: ['images'],
        'fields.image': [
          `variants.${variantPrefix}`,
          `variants.${variantPrefix}-2x`,
        ],
        ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
        ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
      }, { expand: true })
        .then(dealItemsResponse => {
          if (dealItemsResponse) {
            const listingFields = config?.listing?.listingFields;
            const sanitizeConfig = { listingFields };
            dispatch(fetchDealItemsSuccess(dealItemsResponse));
            dispatch(addMarketplaceEntities(dealItemsResponse, sanitizeConfig));
          }
        })
        .catch(e => {
          dispatch(fetchDealItemsError(storableError(e)));
        });
    }

    if (profileListingId) {
      dispatch(fetchProfileListingRequest());
      getAlgoliaRecordById({ indexName: process.env.REACT_APP_ALGOLIA_BUSINESS_INDEX, objectID: profileListingId })
        .then(response => {
          dispatch(fetchProfileListingSuccess(response || {})); // Directly use response
        })
        .catch(e => {
          dispatch(fetchProfileListingError(storableError(e)));
        });
    }

    const listingTypes = {
      product: PRODUCT_LISTING_TYPE,
      service: SERVICE_LISTING_TYPE,
      deal: DEAL_LISTING_TYPE,
    }
    // Fetch related services/products based on same category
    if (categoryLevel2 && !isBookingLink) {
      dispatch(fetchRelatedItemsRequest());
      sdk.listings.query({
        pub_listingType: listingTypes?.[listingType] || SERVICE_LISTING_TYPE,
        pub_categoryLevel2: categoryLevel2,
        page: 1,
        perPage: 7,
        include: ['images'],
        'fields.image': [
          `variants.${variantPrefix}`,
          `variants.${variantPrefix}-2x`,
        ],
        ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
        ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
      }, { expand: true })
        .then(relatedItemsResponse => {
          if (relatedItemsResponse) {
            const indexToRemove = relatedItemsResponse?.data?.data?.findIndex(item => item?.id?.uuid === listingId?.uuid);

            if (indexToRemove !== -1) {
              relatedItemsResponse.data.data.splice(indexToRemove, 1); // Remove the listing
            }
            const listingFields = config?.listing?.listingFields;
            const sanitizeConfig = { listingFields };
            dispatch(addMarketplaceEntities(relatedItemsResponse, sanitizeConfig));
            dispatch(fetchRelatedItemsSuccess(relatedItemsResponse));
          }
        })
        .catch(e => {
          dispatch(fetchRelatedItemsError(storableError(e)));
        });
    }
  });
};
