import { onContactUs } from '../../util/api';

// ================ Action types ================ //
export const SUBMIT_CONTACT_US_REQUEST = 'app/ContactUsPage/SUBMIT_CONTACT_US_REQUEST';
export const SUBMIT_CONTACT_US_SUCCESS = 'app/ContactUsPage/SUBMIT_CONTACT_US_SUCCESS';
export const SUBMIT_CONTACT_US_ERROR = 'app/ContactUsPage/SUBMIT_CONTACT_US_ERROR';

// onContactUs

const initialState = {
  isSubmitting: false,
  submissionSuccess: false,
  submissionError: null,
};

// ================ Reducer ================ //
const contactUsPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SUBMIT_CONTACT_US_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        submissionSuccess: false,
        submissionError: null,
      };
    case SUBMIT_CONTACT_US_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        submissionSuccess: true,
      };
    case SUBMIT_CONTACT_US_ERROR:
      console.error(payload);  // Log error for debugging
      return {
        ...state,
        isSubmitting: false,
        submissionError: payload,
      };

    default:
      return state;
  }
};

export default contactUsPageReducer;


// ================ Action creators ================ //
export const submitContactUsRequest = () => ({
  type: SUBMIT_CONTACT_US_REQUEST,
});

export const submitContactUsSuccess = response => ({
  type: SUBMIT_CONTACT_US_SUCCESS,
  payload: response,
});

export const submitContactUsError = e => ({
  type: SUBMIT_CONTACT_US_ERROR,
  error: true,
  payload: e,
});

// Thunk to submit the "Contact Us" form
export const submitContactUs = (data) => async (dispatch) => {
  try {
    dispatch(submitContactUsRequest());
    const response = await onContactUs(data);
    console.log("response", response)
    dispatch(submitContactUsSuccess(response));

    return response;
  } catch (err) {
    dispatch(submitContactUsError(err));
  }
};

export const loadData = (params, search, config) => async (dispatch, getState, sdk) => {
};
