import mixpanel from 'mixpanel-browser';
import { fetchCurrentUser } from '../../ducks/user.duck';
import { mixPanelEventsConstants, mixPanelEventsScreens } from '../../mixpanel';
import { verifyOtp } from '../../util/api';
import { updateProfile } from '../ProfileSettingsPage/ProfileSettingsPage.duck';

// ================ Action types ================ //
export const VERIFY_OTP_REQUEST = 'app/MerchantFlyerPage/VERIFY_OTP_REQUEST';
export const VERIFY_OTP_SUCCESS = 'app/MerchantFlyerPage/VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_ERROR = 'app/MerchantFlyerPage/VERIFY_OTP_ERROR';

const initialState = {
  isSubmitting: false,
  submissionSuccess: false,
  submissionError: null,
};

// ================ Reducer ================ //
const MerchantFlyerPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case VERIFY_OTP_REQUEST:
      return {
        ...state,
        isSubmitting: true,
        submissionSuccess: false,
        submissionError: null,  
      };
    case VERIFY_OTP_SUCCESS:
      return {
        ...state,
        isSubmitting: false,
        submissionSuccess: true,
      };
    case VERIFY_OTP_ERROR:
      console.error(payload);  // Log error for debugging
      return {
        ...state,
        isSubmitting: false,
        submissionError: payload,
      };

    default:
      return state;
  }
};

export default MerchantFlyerPageReducer;


// ================ Action creators ================ //
export const verifyOtpRequest = () => ({
  type: VERIFY_OTP_REQUEST,
});

export const verifyOtpSuccess = response => ({
  type: VERIFY_OTP_SUCCESS,
  payload: response,
});

export const verifyOtpError = e => ({
  type: VERIFY_OTP_ERROR,
  error: true,
  payload: e,
});

export const verifyPhoneNumber = (data) => async (dispatch, getState, sdk) => {
  try {
    dispatch(verifyOtpRequest());
    const { verificationCode, phoneNumber } = data;
    const isDevelopment = (process.env.REACT_APP_MARKETPLACE_ROOT_URL).includes('icodestaging') || process.env.REACT_APP_ENV === 'development';

    const { currentUser } = getState().user;
    const currentUserId = currentUser?.id?.uuid;

    if (verificationCode == "000000" && isDevelopment) {
      return dispatch(updateProfile({ publicData: { isPhoneNumberVerified: true } }))
        .then(() => {
          dispatch(verifyOtpSuccess());
          mixpanel.track(mixPanelEventsConstants.OTP_VERIFIED, {
            screenName: mixPanelEventsScreens.VERIFYOTP,
            otp_verified: true,
            userID: currentUserId,
            userType: 'customer'
          })
        })
    }
    else {
      return verifyOtp({ phoneNumber: phoneNumber, otp: verificationCode, userId: currentUserId })
        .then(res => {
          dispatch(verifyOtpSuccess());
        })
    }
  }
  catch (err) {
    console.log("Invalid Code", err);
    dispatch(verifyOtpError(err));
  }
}

export const loadData = (params, search, config) => async (dispatch, getState, sdk) => {
  await dispatch(fetchCurrentUser())
    .then(res => {
      console.log(res);


      const user = getState()
      console.log(user, '### ### => user');

    })
    .catch(err => console.log("error fetching current user", err))
};
