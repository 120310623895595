import { fetchCurrentUser } from '../../ducks/user.duck';
import { onGetDealsCategoriesList } from '../../util/api';

/** Action Types */
const DEAL_CATEGORIES_REQUEST = 'app/DealsPage/DEAL_CATEGORIES_REQUEST';
const DEAL_CATEGORIES_SUCCESS = 'app/DealsPage/DEAL_CATEGORIES_SUCCESS';
const DEAL_CATEGORIES_ERROR = 'app/DealsPage/DEAL_CATEGORIES_ERROR';

import algoliasearch from 'algoliasearch';

// Initialize Algolia client and index
const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY
);

/** Initial State */
const initialState = {
  loading: false,
  data: {
    dealCategories: [],
  },
  error: null,
};

/** Action Creators */
export const fetchDealCategoriesRequest = () => ({
  type: DEAL_CATEGORIES_REQUEST,
});

export const fetchDealCategoriesSuccess = data => ({
  type: DEAL_CATEGORIES_SUCCESS,
  payload: data,
});

export const fetchDealCategoriesError = error => ({
  type: DEAL_CATEGORIES_ERROR,
  payload: error,
});

/** Reducer */
const dealCategoriesReducer = (state = initialState, action) => {
  switch (action.type) {
    case DEAL_CATEGORIES_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DEAL_CATEGORIES_SUCCESS:
      return {
        ...state,
        loading: false,
        data: {
          dealCategories: action.payload,
        },
      };
    case DEAL_CATEGORIES_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default dealCategoriesReducer;


// Thunk action creator for Algolia search
export const searchAlgoliaData = (response) => (dispatch, getState) => {
  const index = searchClient.initIndex(process.env.REACT_APP_ALGOLIA_DEALS_INDEX);
  const currentUser = getState().user?.currentUser || {};
  const currentUserId = currentUser?.id?.uuid || '';

  // Call Algolia search API
  index
    .search('', {
      filters: `author.id:${currentUserId}`,
      facets: ['publicData.categoryLevel2'],
      page: 0,
      hitsPerPage: 0,
    })
    .then(res => {
      const facets = res?.facets || {};
      const subCategories = facets['publicData.categoryLevel2'] || {};

      const updatedResponse = Array.isArray(response) ? response : response ? [response] : [];

      const updatedDealCategories = updatedResponse.map(dealCategory => {
        const count = subCategories[dealCategory?.id];
        const countMaybe = count ? { count } : {};
        return {
          ...dealCategory,
          ...countMaybe,
        };
      });

      // Dispatch success action with updated data
      dispatch(fetchDealCategoriesSuccess(updatedDealCategories));
    })
    .catch(error => {
      // Dispatch error action if Algolia search fails
      dispatch(fetchDealCategoriesError(error.message || 'Failed to load data from Algolia'));
    });
};

/** Thunk Action Creator */
export const loadData = () => async (dispatch, getState, sdk) => {
  dispatch(fetchDealCategoriesRequest());

  // Fetch current user data
  await dispatch(fetchCurrentUser());

  // Fetch deal categories list
  return onGetDealsCategoriesList()
    .then(response => {
      // Dispatch searchAlgoliaData with response
      dispatch(searchAlgoliaData(response));
    })
    .catch(error => {
      // Dispatch error action if fetching categories fails
      dispatch(fetchDealCategoriesError(error.message || 'Failed to load data'));
    });
};
