import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from '../../util/reactIntl';
import classNames from 'classnames';
import { ACCOUNT_SETTINGS_PAGES } from '../../routing/routeConfiguration';
import { LinkTabNavHorizontal } from '../../components';

import css from './UserNav.module.css';
import { useSelector } from 'react-redux';

const UserNav = props => {
  const { className, rootClassName, currentPage } = props;
  const classes = classNames(rootClassName || css.root, className);

  const { currentUser } = useSelector(state => state.user);
  const { userType, profileListingId } = currentUser?.attributes?.profile?.publicData || {};

  const isProvider = userType === "provider";
  const currentUserId = currentUser?.id?.uuid;
  const shouldRedirectToMerchantPage = currentUserId && isProvider && profileListingId;

  const tabs = [
    // {
    //   text: <FormattedMessage id="UserNav.yourListings" />,
    //   selected: currentPage === 'ManageListingsPage',
    //   linkProps: {
    //     name: 'ManageListingsPage',
    //   },
    // },
    // !isProvider && {
    //   text: <FormattedMessage id="UserNav.cart" />,
    //   selected: currentPage === 'CartPage',
    //   linkProps: {
    //     name: 'CartPage',
    //   },
    // },
    {
      text: <FormattedMessage id="UserNav.profileSettings" />,
      selected: currentPage === 'ProfileSettingsPage',
      disabled: false,
      linkProps: {
        name: shouldRedirectToMerchantPage ? "ViewBusinessPage" : currentUserId ? "ProfilePage" : "ProfileSettingsPage",
        params: shouldRedirectToMerchantPage ? { listingType: "business-profile", id: profileListingId } : currentUserId ? { id: currentUserId } : {},
      },
    },
    {
      text: <FormattedMessage id="UserNav.accountSettings" />,
      selected: ACCOUNT_SETTINGS_PAGES.includes(currentPage),
      disabled: false,
      linkProps: {
        name: 'ContactDetailsPage',
      },
    },
  ].filter(Boolean);

  return (
    <LinkTabNavHorizontal className={classes} tabRootClassName={css.tab} tabs={tabs} skin="dark" />
  );
};

UserNav.defaultProps = {
  className: null,
  rootClassName: null,
};

const { string } = PropTypes;

UserNav.propTypes = {
  className: string,
  rootClassName: string,
  currentPage: string.isRequired,
};

export default UserNav;
