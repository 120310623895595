import React from 'react';
import css from '../AddToCartButton/AddToCartButton.module.css';
import { Button } from '..';
import { SERVICE_LISTING_TYPE } from '../../util/types';
import IconCollection from '../IconCollection/IconCollection';
import mixpanel from 'mixpanel-browser';
import { mixPanelEventsConstants, mixPanelEventsData, mixPanelEventsScreens } from '../../mixpanel';
import { useSelector } from 'react-redux';

const AddToCartButton = props => {
  const {
    listing,
    count,
    incrementCart,
    isListingPage,
    buttonLabel,
    cartLabel,
    isBooking = false,
    showProductOrderForm = true,
    isOwnListing = false,
    extraParams,
    disabled = false,
    isCheckoutPage,
    history,
    listingNotActive,
  } = props;
  const user = useSelector(state => state?.user?.currentUser);
  if (isBooking || !showProductOrderForm) {
    return null;
  }

  const increaseCount = () => incrementCart(1, extraParams);
  const decreaseCount = () => {
    if (count > 1) {
      incrementCart(-1, extraParams);
    } else if (count === 1 && isCheckoutPage) {
      incrementCart(-1, extraParams);
      history.push('/');
    }
  };

  const { bookingEnd, bookingStart, staffId, type } = extraParams || {};
  const submitDisabled = disabled || listingNotActive || (type === SERVICE_LISTING_TYPE
    ? !(bookingEnd && bookingStart && staffId && type)
    : !type);

  const currentStock = listing?.currentStock?.attributes?.quantity || 1;
  const isMaxItems = currentStock <= count;

  if (!count || (count === 0 && currentStock > 0)) {
    return (
      <Button
        type="button"
        disabled={submitDisabled}
        onClick={() => {
          if (!isOwnListing) {
            increaseCount();
          }
        }}
        className={css.cartButton}
      >
        {buttonLabel}
      </Button>
    );
  } else {
    return (
      <div className={css.cartContent}>
        {isListingPage && (cartLabel)}
        <div className={css.buttonContainer}>
          <Button className={css.smallButton} onClick={decreaseCount} type="button" disabled={count === 1}>
            <IconCollection name="minusIcon" />

          </Button>
          <span className={css.countContainer}>{count}</span>
          <Button className={css.smallButton} disabled={isMaxItems} onClick={increaseCount} type="button">
            <IconCollection name="plusIcon" />

          </Button>
        </div>
      </div>
    );
  }
};

AddToCartButton.defaultProps = {
  isListingPage: false,
};

export default AddToCartButton;