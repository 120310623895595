import loadable from '@loadable/component';
import classNames from 'classnames';
import omit from 'lodash/omit';
import {
  array,
  arrayOf,
  bool,
  func,
  node,
  number,
  object,
  oneOfType,
  shape,
  string,
} from 'prop-types';
import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import {
  INQUIRY_PROCESS_NAME,
  getSupportedProcessesInfo,
  isBookingProcess,
  isDealProcess,
  isPurchaseProcess,
  resolveLatestProcessName,
} from '../../transactions/transaction';
import {
  displayDeliveryPickup,
  displayDeliveryShipping,
  displayPrice,
} from '../../util/configHelpers';
import { formatMoney } from '../../util/currency';
import { userDisplayNameAsString } from '../../util/data';
import { FormattedMessage, injectIntl, intlShape } from '../../util/reactIntl';
import {
  DEAL_LISTING_TYPE,
  LINE_ITEM_DAY,
  LINE_ITEM_HOUR,
  LINE_ITEM_ITEM,
  LINE_ITEM_NIGHT,
  LISTING_STATE_CLOSED,
  SERVICE_LISTING_TYPE,
  STOCK_INFINITE_MULTIPLE_ITEMS,
  STOCK_MULTIPLE_ITEMS,
  propTypes,
} from '../../util/types';
import { createSlug, parse, stringify } from '../../util/urlHelpers';

import { Button, H1, ModalInMobile, NamedLink, PrimaryButton } from '../../components';
import AddToCartButton from '../AddToCartButton/AddToCartButton';

import { createResourceLocatorString, findRouteByRouteName } from '../../util/routes';
import css from './OrderPanel.module.css';

const BookingTimeForm = loadable(() =>
  import(/* webpackChunkName: "BookingTimeForm" */ './BookingTimeForm/BookingTimeForm')
);
const BookingDatesForm = loadable(() =>
  import(/* webpackChunkName: "BookingDatesForm" */ './BookingDatesForm/BookingDatesForm')
);
const InquiryWithoutPaymentForm = loadable(() =>
  import(
    /* webpackChunkName: "InquiryWithoutPaymentForm" */ './InquiryWithoutPaymentForm/InquiryWithoutPaymentForm'
  )
);
const ProductOrderForm = loadable(() =>
  import(/* webpackChunkName: "ProductOrderForm" */ './ProductOrderForm/ProductOrderForm')
);

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;
const TODAY = new Date();

const priceData = (price, currency, intl) => {
  if (price && price.currency === currency) {
    const formattedPrice = formatMoney(intl, price);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (price) {
    return {
      formattedPrice: `(${price.currency})`,
      priceTitle: `Unsupported currency (${price.currency})`,
    };
  }
  return {};
};

const openOrderModal = (isOwnListing, isClosed, history, location) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), orderOpen: true })}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

const closeOrderModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'orderOpen');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};

const handleSubmit = (
  isOwnListing,
  isClosed,
  isInquiryWithoutPayment,
  onSubmit,
  history,
  location
) => {
  // TODO: currently, inquiry-process does not have any form to ask more order data.
  // We can submit without opening any inquiry/order modal.
  return isInquiryWithoutPayment
    ? () => onSubmit({})
    : () => openOrderModal(isOwnListing, isClosed, history, location);
};

const dateFormattingOptions = { month: 'short', day: 'numeric', weekday: 'short' };

const PriceMaybe = props => {
  const {
    price,
    publicData,
    validListingTypes,
    intl,
    marketplaceCurrency,
    showCurrencyMismatch = false,
  } = props;
  const { listingType, unitType } = publicData || {};

  const foundListingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const showPrice = displayPrice(foundListingTypeConfig);
  if (!showPrice || !price) {
    return null;
  }

  // Get formatted price or currency code if the currency does not match with marketplace currency
  const { formattedPrice, priceTitle } = priceData(price, marketplaceCurrency, intl);
  // TODO: In CTA, we don't have space to show proper error message for a mismatch of marketplace currency
  //       Instead, we show the currency code in place of the price
  return showCurrencyMismatch ? (
    <div className={css.priceContainerInCTA}>
      <div className={css.priceValue} title={priceTitle}>
        {formattedPrice}
      </div>
      <div className={css.perUnitInCTA}>
        <FormattedMessage id="OrderPanel.perUnit" values={{ unitType }} />
      </div>
    </div>
  ) : (
    <div className={css.priceContainer}>
      <p className={css.price}>{formatMoney(intl, price)}</p>
      <div className={css.perUnit}>
        <FormattedMessage id="OrderPanel.perUnit" values={{ unitType }} />
      </div>
    </div>
  );
};

const OrderPanel = props => {
  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    validListingTypes,
    lineItemUnitType: lineItemUnitTypeMaybe,
    isOwnListing,
    onSubmit,
    title,
    titleDesktop,
    author,
    authorLink,
    onManageDisableScrolling,
    onFetchTimeSlots,
    monthlyTimeSlots,
    history,
    routeConfiguration,
    location,
    intl,
    onFetchTransactionLineItems,
    onContactUser,
    lineItems,
    marketplaceCurrency,
    dayCountAvailableForBooking,
    marketplaceName,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    payoutDetailsWarning,
    onFetchStaffListings,
    config,
    staffListings,
    fetchStaffListingsInProgress,
    fetchStaffListingsError,
    cartProps,
    isDealItem,
    dealItemsListings = [],
    dealServicesData = [],
    setDealServicesData,
    setIsBookingModalOpen,
    isDealValid,
    isDealStarted,
    currentUser,
    isBookingLink,
    merchantDetails,
    callSetInitialValues,
    onInitializeCardPaymentData,
  } = props;

  const servicesOnly = dealItemsListings.filter(item => {
    const { listingType } = item?.attributes?.publicData;
    return listingType === SERVICE_LISTING_TYPE;
  })

  const isDealItemClosed = dealItemsListings.reduce((acc, item) => {
    if (item?.attributes?.state !== "published") {
      acc = true;
    }
    return acc;
  }, false);

  // Explicity make it true if there is no service in deal
  const allServicesBooked = !servicesOnly?.length ? true :
    servicesOnly.reduce((acc, service) => dealServicesData.find(bookedService => bookedService?.id === service?.id?.uuid), false)

  const listingTitle = listing?.attributes?.title || '';
  const listingImage = listing?.images?.[0]?.attributes?.variants?.['listing-card']?.url || null;

  const publicData = listing?.attributes?.publicData || {};
  const { listingType, unitType, transactionProcessAlias = '' } = publicData || {};
  const processName = resolveLatestProcessName(transactionProcessAlias.split('/')[0]);
  const lineItemUnitType = lineItemUnitTypeMaybe || `line-item/${unitType}`;

  const price = listing?.attributes?.price;
  const isPaymentProcess = processName !== INQUIRY_PROCESS_NAME;

  const showPriceMissing = isPaymentProcess && !price;
  const PriceMissing = () => {
    return (
      <p className={css.error}>
        <FormattedMessage id="OrderPanel.listingPriceMissing" />
      </p>
    );
  };
  const showInvalidCurrency = isPaymentProcess && price?.currency !== marketplaceCurrency;
  const InvalidCurrency = () => {
    return (
      <p className={css.error}>
        <FormattedMessage id="OrderPanel.listingCurrencyInvalid" />
      </p>
    );
  };

  const timeZone = listing?.attributes?.availabilityPlan?.timezone;
  const isClosed = listing?.attributes?.state === LISTING_STATE_CLOSED;

  const isBooking = isBookingProcess(processName);
  const shouldHaveBookingTime = isBooking && [LINE_ITEM_HOUR].includes(lineItemUnitType);
  const showBookingTimeForm = shouldHaveBookingTime && !isClosed && timeZone;

  const shouldHaveBookingDates =
    isDealProcess && [LINE_ITEM_DAY, LINE_ITEM_NIGHT].includes(lineItemUnitType);
  const showBookingDatesForm = shouldHaveBookingDates && !isClosed && timeZone;

  // The listing resource has a relationship: `currentStock`,
  // which you should include when making API calls.
  const isPurchase = isPurchaseProcess(processName);
  const currentStock = listing.currentStock?.attributes?.quantity;
  const isOutOfStock = isPurchase && lineItemUnitType === LINE_ITEM_ITEM && currentStock === 0;

  // Show form only when stock is fully loaded. This avoids "Out of stock" UI by
  // default before all data has been downloaded.
  const showProductOrderForm = isPurchase && typeof currentStock === 'number';

  const showInquiryForm = processName === INQUIRY_PROCESS_NAME;

  const supportedProcessesInfo = getSupportedProcessesInfo();
  const isKnownProcess = supportedProcessesInfo.map(info => info.name).includes(processName);

  const { pickupEnabled, shippingEnabled } = listing?.attributes?.publicData || {};

  const listingTypeConfig = validListingTypes.find(conf => conf.listingType === listingType);
  const displayShipping = displayDeliveryShipping(listingTypeConfig);
  const displayPickup = displayDeliveryPickup(listingTypeConfig);
  const allowOrdersOfMultipleItems = [STOCK_MULTIPLE_ITEMS, STOCK_INFINITE_MULTIPLE_ITEMS].includes(
    listingTypeConfig?.stockType
  );

  const showClosedListingHelpText = listing.id && isClosed;
  const isOrderOpen = !!parse(location.search).orderOpen;

  const subTitleText = showClosedListingHelpText
    ? intl.formatMessage({ id: 'OrderPanel.subTitleClosedListing' })
    : null;

  const authorDisplayName = userDisplayNameAsString(author, '');

  const arabicLanguage = typeof window !== 'undefined' && localStorage.getItem("language") === "Arabic";

  const classes = classNames(rootClassName || css.root, className, arabicLanguage ? css.arabicLanguage : null);
  const titleClasses = classNames(titleClassName || css.orderTitle);


  const shouldDisableDeal = listingType === DEAL_LISTING_TYPE ? (!allServicesBooked || !isDealValid || !isDealStarted || isDealItemClosed || !dealItemsListings?.length) : false;

  const addToCartButton = ({ extraParams, shouldDisable }) => (
    <AddToCartButton
      showProductOrderForm={true}
      isBooking={false}
      buttonLabel={<FormattedMessage id="AddToCartButton.label" />}
      cartLabel={<FormattedMessage id="AddToCartButton.cartLabel" />}
      disabled={shouldDisable || shouldDisableDeal}
      extraParams={{
        ...extraParams,
        title: listingTitle,
        image: listingImage
      }}
      {...cartProps}
    />
  )

  const getUserCheckout = (checkoutData) => {
    const listing = checkoutData?.listing || {};
    const listingId = listing?.id?.uuid;
    const { displayName: authorName } = listing?.author?.attributes?.profile || {};

    const { setInitialValues } = findRouteByRouteName('CheckoutPage', routeConfiguration);
    callSetInitialValues(setInitialValues, checkoutData, true);

    // Clear previous Stripe errors from store if there is any
    onInitializeCardPaymentData();

    // Redirect to CheckoutPage
    history.push(
      createResourceLocatorString(
        'CheckoutPage',
        routeConfiguration,
        { id: listingId, slug: createSlug(authorName) },
      )
    );
  }

  return (
    <div className={classes}>
      <ModalInMobile
        containerClassName={css.modalContainer}
        id="OrderFormInModal"
        isModalOpenOnMobile={isOrderOpen}
        onClose={() => closeOrderModal(history, location)}
        showAsModalMaxWidth={MODAL_BREAKPOINT}
        onManageDisableScrolling={onManageDisableScrolling}
        usePortal
        className={classNames(arabicLanguage ? css.arabicLanguage : null, css.modalMain)}
      >
        {/* <div className={css.modalHeading}>
          <H1 className={css.heading}>{title}</H1>
        </div> */}

        {showBookingTimeForm ? <div className={css.selectTimeText}><FormattedMessage id='OrderPanel.selectTime' /></div> : null}

        <div className={css.panelContents}>
          {listingType === DEAL_LISTING_TYPE && !isDealStarted ? <p className={css.noMoreText}>
            <FormattedMessage id='OrderPanel.hasNotStarted' />
          </p>
            : listingType === DEAL_LISTING_TYPE && !isDealValid ? <p className={css.noMoreText}>
              <FormattedMessage id='OrderPanel.noMoreActive' />
            </p>
              : null}


          {showPriceMissing ? (
            <PriceMissing />
          ) : showInvalidCurrency ? (
            <InvalidCurrency />
          ) : showBookingTimeForm ? (
            <BookingTimeForm
              className={css.bookingForm}
              formId="OrderPanelBookingTimeForm"
              lineItemUnitType={lineItemUnitType}
              onSubmit={onSubmit}
              price={price}
              marketplaceCurrency={marketplaceCurrency}
              dayCountAvailableForBooking={dayCountAvailableForBooking}
              listingId={listing.id}
              isOwnListing={isOwnListing}
              monthlyTimeSlots={monthlyTimeSlots}
              onFetchTimeSlots={onFetchTimeSlots}
              startDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
              endDatePlaceholder={intl.formatDate(TODAY, dateFormattingOptions)}
              timeZone={timeZone}
              marketplaceName={marketplaceName}
              onFetchTransactionLineItems={onFetchTransactionLineItems}
              onFetchStaffListings={onFetchStaffListings}
              lineItems={lineItems}
              fetchLineItemsInProgress={fetchLineItemsInProgress}
              fetchLineItemsError={fetchLineItemsError}
              payoutDetailsWarning={payoutDetailsWarning}
              publicData={publicData}
              config={config}
              staffListings={staffListings}
              fetchStaffListingsError={fetchStaffListingsError}
              fetchStaffListingsInProgress={fetchStaffListingsInProgress}
              addToCartButton={addToCartButton}
              isDealItem={isDealItem}
              setDealServicesData={setDealServicesData}
              setIsBookingModalOpen={setIsBookingModalOpen}
              currentUser={currentUser}
              isBookingLink={isBookingLink}
              listing={listing}
              merchantDetails={merchantDetails}
              getUserCheckout={getUserCheckout}
              history={history}
              routeConfiguration={routeConfiguration}
            />
          ) : showBookingDatesForm ? (
            <BookingDatesForm
              className={css.bookingForm}
              formId="OrderPanelBookingDatesForm"
              lineItemUnitType={lineItemUnitType}
              onSubmit={onSubmit}
              price={price}
              marketplaceCurrency={marketplaceCurrency}
              dayCountAvailableForBooking={dayCountAvailableForBooking}
              listingId={listing.id}
              isOwnListing={isOwnListing}
              monthlyTimeSlots={monthlyTimeSlots}
              onFetchTimeSlots={onFetchTimeSlots}
              timeZone={timeZone}
              marketplaceName={marketplaceName}
              onFetchTransactionLineItems={onFetchTransactionLineItems}
              lineItems={lineItems}
              fetchLineItemsInProgress={fetchLineItemsInProgress}
              fetchLineItemsError={fetchLineItemsError}
              payoutDetailsWarning={payoutDetailsWarning}
              addToCartButton={addToCartButton}
              showBreakdown={false}
              listingPublicData={publicData}
              dealItemsListings={dealItemsListings}
              dealServicesData={dealServicesData}
              currentUser={currentUser}
              isBookingLink={isBookingLink}
              listing={listing}
              merchantDetails={merchantDetails}
              shouldDisableDeal={shouldDisableDeal}
              getUserCheckout={getUserCheckout}
              history={history}
              routeConfiguration={routeConfiguration}
            />
          ) : showProductOrderForm ? (
            <ProductOrderForm
              formId="OrderPanelProductOrderForm"
              onSubmit={onSubmit}
              price={price}
              marketplaceCurrency={marketplaceCurrency}
              currentStock={currentStock}
              allowOrdersOfMultipleItems={allowOrdersOfMultipleItems}
              pickupEnabled={pickupEnabled && displayPickup}
              shippingEnabled={shippingEnabled && displayShipping}
              displayDeliveryMethod={displayPickup || displayShipping}
              listingId={listing.id}
              isOwnListing={isOwnListing}
              marketplaceName={marketplaceName}
              onFetchTransactionLineItems={onFetchTransactionLineItems}
              onContactUser={onContactUser}
              lineItems={lineItems}
              fetchLineItemsInProgress={fetchLineItemsInProgress}
              fetchLineItemsError={fetchLineItemsError}
              payoutDetailsWarning={payoutDetailsWarning}
              addToCartButton={addToCartButton}
              isBookingLink={isBookingLink}
              listing={listing}
              merchantDetails={merchantDetails}
              getUserCheckout={getUserCheckout}
              history={history}
              routeConfiguration={routeConfiguration}
              isClosed={isClosed}
            />
          ) : showInquiryForm ? (
            <InquiryWithoutPaymentForm formId="OrderPanelInquiryForm" onSubmit={onSubmit} />
          ) : !isKnownProcess ? (
            <p className={css.errorSidebar}>
              <FormattedMessage id="OrderPanel.unknownTransactionProcess" />
            </p>
          ) : null}
        </div>
      </ModalInMobile>

      {cartProps.count ? (
        <NamedLink className={css.cartLink} name="CartPage">
          {listingType === SERVICE_LISTING_TYPE ?
            <FormattedMessage id="OrderPanel.completeBooking" />
            :
            <FormattedMessage id="OrderPanel.completeOrder" />
          }
        </NamedLink>
      ) : null}

      {/* {!isDealItem ? (
        <div className={css.openOrderForm}>
          <PriceMaybe
            price={price}
            publicData={publicData}
            validListingTypes={validListingTypes}
            intl={intl}
            marketplaceCurrency={marketplaceCurrency}
            showCurrencyMismatch
          />
          {isClosed ? (
            <div className={css.closedListingButton}>
              <FormattedMessage id="OrderPanel.closedListingButtonText" />
            </div>
          ) : (
            <Button
              className={css.ctaButton}
              onClick={handleSubmit(
                isOwnListing,
                isClosed,
                showInquiryForm,
                onSubmit,
                history,
                location
              )}
              disabled={isOutOfStock || shouldDisableDeal}
            >
              {isBooking ? (
                <FormattedMessage id="OrderPanel.ctaButtonMessageBooking" />
              ) : isOutOfStock ? (
                <FormattedMessage id="OrderPanel.ctaButtonMessageNoStock" />
              ) : isPurchase ? (
                <FormattedMessage id="OrderPanel.ctaButtonMessagePurchase" />
              ) : (
                <FormattedMessage id="OrderPanel.ctaButtonMessageInquiry" />
              )}
            </Button>
          )}
        </div>)
        : null} */}

    </div>
  );
};

OrderPanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  authorLink: null,
  payoutDetailsWarning: null,
  titleDesktop: null,
  subTitle: null,
  monthlyTimeSlots: null,
  lineItems: null,
  fetchLineItemsError: null,
};

OrderPanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  validListingTypes: arrayOf(
    shape({
      listingType: string.isRequired,
      transactionType: shape({
        process: string.isRequired,
        alias: string.isRequired,
        unitType: string.isRequired,
      }).isRequired,
    })
  ).isRequired,
  isOwnListing: bool,
  author: oneOfType([propTypes.user, propTypes.currentUser]).isRequired,
  authorLink: node,
  payoutDetailsWarning: node,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  titleDesktop: node,
  subTitle: oneOfType([node, string]),
  onManageDisableScrolling: func.isRequired,

  onFetchTimeSlots: func.isRequired,
  monthlyTimeSlots: object,
  onFetchTransactionLineItems: func.isRequired,
  onContactUser: func,
  lineItems: array,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,
  marketplaceCurrency: string.isRequired,
  dayCountAvailableForBooking: number.isRequired,
  marketplaceName: string.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(
  withRouter,
  injectIntl
)(OrderPanel);
