import { onGetDealsCategoriesList, onGetDetailedUserRatings, onGetUserReviews } from '../../util/api';
import { types as sdkTypes } from '../../util/sdkLoader';
import { categoriesListingFn } from '../EditListingPage/EditListingPage.duck';

const { UUID } = sdkTypes;

// ================ Action types ================ //

export const SEARCH_LISTINGS_REQUEST = 'app/ViewBusinessPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/ViewBusinessPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/ViewBusinessPage/SEARCH_LISTINGS_ERROR';
export const ALGOLIA_SEARCH_RESULTS = 'app/ViewBusinessPage/ALGOLIA_SEARCH_RESULTS';

export const DEALS_CATEGORIES_REQUEST = 'app/ViewBusinessPage/DEALS_CATEGORIES_REQUEST';
export const DEALS_CATEGORIES_SUCCESS = 'app/ViewBusinessPage/DEALS_CATEGORIES_SUCCESS';
export const DEALS_CATEGORIES_ERROR = 'app/ViewBusinessPage/DEALS_CATEGORIES_ERROR';

export const FETCH_BUSINESS_REQUEST = 'app/ViewBusinessPage/FETCH_BUSINESS_REQUEST';
export const FETCH_BUSINESS_SUCCESS = 'app/ViewBusinessPage/FETCH_BUSINESS_SUCCESS';
export const FETCH_BUSINESS_ERROR = 'app/ViewBusinessPage/FETCH_BUSINESS_ERROR';

export const FETCH_STAFFS_REQUEST = 'app/ViewBusinessPage/FETCH_STAFFS_REQUEST';
export const FETCH_STAFFS_SUCCESS = 'app/ViewBusinessPage/FETCH_STAFFS_SUCCESS';
export const FETCH_STAFFS_ERROR = 'app/ViewBusinessPage/FETCH_STAFFS_ERROR';

export const FETCH_USER_REVIEWS_REQUEST = 'app/ViewBusinessPage/FETCH_USER_REVIEWS_REQUEST';
export const FETCH_USER_REVIEWS_SUCCESS = 'app/ViewBusinessPage/FETCH_USER_REVIEWS_SUCCESS';
export const FETCH_USER_REVIEWS_ERROR = 'app/ViewBusinessPage/FETCH_USER_REVIEWS_ERROR';

export const FETCH_USER_RATINGS_REQUEST = 'app/ViewBusinessPage/FETCH_USER_RATINGS_REQUEST';
export const FETCH_USER_RATINGS_SUCCESS = 'app/ViewBusinessPage/FETCH_USER_RATINGS_SUCCESS';
export const FETCH_USER_RATINGS_ERROR = 'app/ViewBusinessPage/FETCH_USER_RATINGS_ERROR';

import algoliasearch from 'algoliasearch';

// Initialize Algolia client and index
const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_SEARCH_API_KEY
);

// ================ Reducer ================ //

const initialState = {
  searchParams: null,
  pagination: null,
  searchInProgress: false,
  searchListings: [],
  searchListingsError: null,
  algoliaSearchResults: [],
  dealsCategoriesRequest: false,
  dealsCategories: [],
  dealsCategoriesError: null,
  businessListing: null,
  fetchBusinessInProgress: false,
  fetchBusinessError: null,
  staffListings: [],
  fetchStaffsInProgress: false,
  fetchStaffsError: null,
  userReviews: [],
  fetchUserReviewsError: null,
  fetchUserReviewsInProgress: false,
  userRatings: {},
  fetchUserRatingsError: null,
  fetchUserRatingsInProgress: false,
};

const ViewBusinessPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case ALGOLIA_SEARCH_RESULTS:
      return {
        ...state,
        algoliaSearchResults: payload,
      };
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload,
        searchInProgress: true,
        pagination: null,
        searchListings: [],
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS:
      return {
        ...state,
        searchInProgress: false,
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return {
        ...state,
        searchInProgress: false,
        searchListingsError: payload,
        searchListings: [],
        pagination: null,
      };

    case DEALS_CATEGORIES_REQUEST:
      return {
        ...state,
        dealsCategoriesRequest: true,
        dealsCategoriesSuccess: [],
      };

    case DEALS_CATEGORIES_SUCCESS:
      return {
        ...state,
        dealsCategoriesRequest: false,
        dealsCategoriesSuccess: payload,
      };

    case DEALS_CATEGORIES_ERROR:
      return {
        ...state,
        dealsCategoriesError: payload,
      };

    case FETCH_BUSINESS_REQUEST:
      return {
        ...state,
        fetchBusinessInProgress: true,
        fetchBusinessError: null,
      };

    case FETCH_BUSINESS_SUCCESS:
      return {
        ...state,
        fetchBusinessInProgress: false,
        businessListing: payload,
      };

    case FETCH_BUSINESS_ERROR:
      return {
        ...state,
        fetchBusinessError: payload,
      };

    // Staffs
    case FETCH_STAFFS_REQUEST:
      return {
        ...state,
        fetchStaffsInProgress: true,
        fetchStaffsError: null,
      };

    case FETCH_STAFFS_SUCCESS:
      return {
        ...state,
        fetchStaffsInProgress: false,
        staffListings: payload,
      };

    case FETCH_STAFFS_ERROR:
      return {
        ...state,
        fetchStaffsError: payload,
      };

    // Reviews
    case FETCH_USER_REVIEWS_REQUEST:
      return { ...state, fetchUserReviewsInProgress: true, fetchUserReviewsError: null };
    case FETCH_USER_REVIEWS_SUCCESS:
      return { ...state, fetchUserReviewsInProgress: false, userReviews: payload };
    case FETCH_USER_REVIEWS_ERROR:
      return { ...state, fetchUserReviewsError: payload, fetchUserReviewsInProgress: false, userReviews: [] };

    // Ratings
    case FETCH_USER_RATINGS_REQUEST:
      return { ...state, fetchUserRatingsInProgress: true, fetchUserRatingsError: null };
    case FETCH_USER_RATINGS_SUCCESS:
      return { ...state, fetchUserRatingsInProgress: false, userRatings: payload };
    case FETCH_USER_RATINGS_ERROR:
      return { ...state, fetchUserRatingsError: payload, fetchUserRatingsInProgress: false, userRatings: {} };

    default:
      return state;
  }
};

export default ViewBusinessPageReducer;

// ================ Action creators ================ //

export const categoriesRequest = () => ({
  type: DEALS_CATEGORIES_REQUEST,
});

export const categoriesSuccess = payload => ({
  type: DEALS_CATEGORIES_SUCCESS,
  payload: payload,
});

export const categoriesError = response => ({
  type: DEALS_CATEGORIES_ERROR,
  payload: response,
});

export const algoliasearchResults = data => ({
  type: ALGOLIA_SEARCH_RESULTS,
  payload: data,
});
export const searchListingsRequest = searchParams => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: searchParams,
});

export const searchListingsSuccess = response => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: response,
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

/**
 * Trigger fetching of business details
 */
export const fetchBusinessRequest = () => ({
  type: FETCH_BUSINESS_REQUEST,
});

export const fetchBusinessSuccess = response => {
  return ({
    type: FETCH_BUSINESS_SUCCESS,
    payload: response,
  })
};

export const fetchBusinessError = e => ({
  type: FETCH_BUSINESS_ERROR,
  error: true,
  payload: e,
});

/**
 * Trigger fetching of staffs
 */
export const fetchStaffsRequest = () => ({
  type: FETCH_STAFFS_REQUEST,
});

export const fetchStaffsSuccess = response => {
  return ({
    type: FETCH_STAFFS_SUCCESS,
    payload: response,
  })
};

export const fetchStaffsError = e => ({
  type: FETCH_STAFFS_ERROR,
  error: true,
  payload: e,
});

// Reviews
export const fetchUserReviewsRequest = () => ({ type: FETCH_USER_REVIEWS_REQUEST });
export const fetchUserReviewsSuccess = reviews => ({ type: FETCH_USER_REVIEWS_SUCCESS, payload: reviews });
export const fetchUserReviewsError = error => ({
  type: FETCH_USER_REVIEWS_ERROR,
  error: true,
  payload: error,
});

// Ratings
export const fetchUserRatingsRequest = () => ({ type: FETCH_USER_RATINGS_REQUEST });
export const fetchUserRatingsSuccess = ratings => ({ type: FETCH_USER_RATINGS_SUCCESS, payload: ratings });
export const fetchUserRatingsError = error => ({
  type: FETCH_USER_RATINGS_ERROR,
  error: true,
  payload: error,
});

export const searchListings = searchParams => async (dispatch, getState, sdk) => {
  try {
    //fetching result in-progress
    dispatch(searchListingsRequest());
  } catch (error) {
    console.error('error..', error);
    dispatch(searchListingsSuccess());
  }
};

export const getAlgoliasearchResults = params => (dispatch, getState, sdk) => {
  try {
    dispatch(algoliasearchResults(params));
  } catch (error) { }
};

export const categoriesListing = () => async dispatch => {
  dispatch(categoriesRequest);
  try {
    const response = await onGetDealsCategoriesList();
    dispatch(categoriesSuccess(response));
    return response;
  } catch (error) {
    dispatch(categoriesError(error.message));
  }
};

export const fetchAuthorData = ({ userId }) => async (dispatch, getState, sdk) => {
  try {
    const result = await sdk.users.show({ id: new UUID(userId) });
    return result?.data?.data;
  } catch (error) { }
};


// Thunk action creator for Algolia search
export const searchAlgoliaData = ({ indexName, querySearch, filters }) => (dispatch, getState) => {
  const index = searchClient.initIndex(indexName);

  return index
    .search(querySearch, {
      filters: filters?.filters || "", // Handle undefined filters
      facets: filters?.facets || [],
      page: filters?.page || 0,
      hitsPerPage: filters?.hitsPerPage || 10,
    })
    .then(res => res) // Return the search result
    .catch(e => {
      console.error("Algolia search error:", e);
      return null; // Prevent throwing errors
    });
};

export const getAlgoliaRecordById = ({ indexName, objectID }) => {
  const index = searchClient.initIndex(indexName);

  return index
    .getObject(objectID)
    .then(res => res)  // Return the specific record
    .catch(e => {
      console.error("Error fetching record:", e);
      return null;  // Prevent errors from propagating
    });
};

export const fetchUserReviews = userId => (dispatch, getState, sdk) => {
  dispatch(fetchUserReviewsRequest());
  return onGetUserReviews({ otherUserId: userId?.uuid || userId, reviewerType: "customer", entityType: "merchant", page: 1, limit: 10 })
    .then(response => {
      const payload = response || {};
      dispatch(fetchUserReviewsSuccess(payload));
    })
    .catch(err => {
      console.log('err', err);
      dispatch(fetchUserReviewsError(storableError(e)));
    });
};

export const fetchUserRatings = userId => (dispatch, getState, sdk) => {
  dispatch(fetchUserRatingsRequest());

  return onGetDetailedUserRatings({ id: userId?.uuid || userId, reviewerType: "customer" })
    .then(response => {
      const payload = response || {};
      dispatch(fetchUserRatingsSuccess(payload));
    })
    .catch(err => {
      console.log('err', err);
      dispatch(fetchUserRatingsError(storableError(e)));
    });
};

export const loadData = (params, search, config) => async (dispatch, getState) => {
  const { listingType, id: listingId } = params;

  if (listingId) {
    dispatch(fetchBusinessRequest());

    try {
      // Fetch the business data by listingId
      const businessData = await getAlgoliaRecordById({
        indexName: process.env.REACT_APP_ALGOLIA_BUSINESS_INDEX,
        objectID: listingId,
      });

      if (businessData) {
        dispatch(fetchBusinessSuccess(businessData));

        const authorId = businessData?.author?.id;
        dispatch(fetchUserReviews(authorId));
        dispatch(fetchUserRatings(authorId));

        if (authorId) {
          dispatch(fetchStaffsRequest());

          // Fetch the staff data based on author ID
          dispatch(
            searchAlgoliaData({
              indexName: process.env.REACT_APP_ALGOLIA_STAFF_INDEX,
              querySearch: "",
              filters: { filters: `author.id:${authorId}` },
            })
          )
            .then(res => {
              const staffs = res?.hits || [];
              dispatch(fetchStaffsSuccess(staffs));
            })
            .catch(e => {
              console.error("Error fetching staff data:", e);
              dispatch(fetchStaffsError(e));
            });
        }
      } else {
        dispatch(fetchBusinessError("Business not found"));
      }
    } catch (e) {
      console.error("Error fetching business data:", e);
      dispatch(fetchBusinessError(e));
    }
  }

  // Fetch other data concurrently using Promise.allSettled
  return Promise.allSettled([
    dispatch(categoriesListing()),
    dispatch(categoriesListingFn()),
    dispatch(searchListings()),
  ]);
};