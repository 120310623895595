import { clearCurrentUser, fetchCurrentUser } from './user.duck';
import {
  completeYourProfile,
  createUserWithIdp,
  onCheckPhoneNumberExists,
  onCreateCustomer,
  sendVerificationOtp,
} from '../util/api';
import { storableError } from '../util/errors';
import * as log from '../util/log';
import { updateProfile } from '../containers/ProfileSettingsPage/ProfileSettingsPage.duck';
import mixpanel from 'mixpanel-browser';
import { mixPanelEventsConstants, mixPanelEventsData, mixPanelEventsScreens } from '../mixpanel';
const authenticated = authInfo => authInfo?.isAnonymous === false;
const loggedInAs = authInfo => authInfo?.isLoggedInAs === true;

// ================ Action types ================ //

export const AUTH_INFO_REQUEST = 'app/auth/AUTH_INFO_REQUEST';
export const AUTH_INFO_SUCCESS = 'app/auth/AUTH_INFO_SUCCESS';

export const LOGIN_REQUEST = 'app/auth/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'app/auth/LOGIN_SUCCESS';
export const LOGIN_ERROR = 'app/auth/LOGIN_ERROR';

export const LOGOUT_REQUEST = 'app/auth/LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'app/auth/LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'app/auth/LOGOUT_ERROR';

export const SIGNUP_REQUEST = 'app/auth/SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'app/auth/SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'app/auth/SIGNUP_ERROR';

export const CONFIRM_REQUEST = 'app/auth/CONFIRM_REQUEST';
export const CONFIRM_SUCCESS = 'app/auth/CONFIRM_SUCCESS';
export const CONFIRM_ERROR = 'app/auth/CONFIRM_ERROR';

// Generic user_logout action that can be handled elsewhere
// E.g. src/reducers.js clears store as a consequence
export const USER_LOGOUT = 'app/USER_LOGOUT';

// ================ Reducer ================ //

const initialState = {
  isAuthenticated: false,

  // is marketplace operator logged in as a marketplace user
  isLoggedInAs: false,

  // scopes associated with current token
  authScopes: [],

  // auth info
  authInfoLoaded: false,

  // login
  loginError: null,
  loginInProgress: false,

  // logout
  logoutError: null,
  logoutInProgress: false,

  // signup
  signupError: null,
  signupInProgress: false,

  // confirm (create use with idp)
  confirmError: null,
  confirmInProgress: false,
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case AUTH_INFO_REQUEST:
      return state;
    case AUTH_INFO_SUCCESS:
      return {
        ...state,
        authInfoLoaded: true,
        isAuthenticated: authenticated(payload),
        isLoggedInAs: loggedInAs(payload),
        authScopes: payload.scopes,
      };

    case LOGIN_REQUEST:
      return {
        ...state,
        loginInProgress: true,
        loginError: null,
        logoutError: null,
        signupError: null,
      };
    case LOGIN_SUCCESS:
      return { ...state, loginInProgress: false, isAuthenticated: true };
    case LOGIN_ERROR:
      return { ...state, loginInProgress: false, loginError: payload };

    case LOGOUT_REQUEST:
      return { ...state, logoutInProgress: true, loginError: null, logoutError: null };
    case LOGOUT_SUCCESS:
      return {
        ...state,
        logoutInProgress: false,
        isAuthenticated: false,
        isLoggedInAs: false,
        authScopes: [],
      };
    case LOGOUT_ERROR:
      return { ...state, logoutInProgress: false, logoutError: payload };

    case SIGNUP_REQUEST:
      return { ...state, signupInProgress: true, loginError: null, signupError: null };
    case SIGNUP_SUCCESS:
      return { ...state, signupInProgress: false };
    case SIGNUP_ERROR:
      return { ...state, signupInProgress: false, signupError: payload };

    case CONFIRM_REQUEST:
      return { ...state, confirmInProgress: true, loginError: null, confirmError: null };
    case CONFIRM_SUCCESS:
      return { ...state, confirmInProgress: false, isAuthenticated: true };
    case CONFIRM_ERROR:
      return { ...state, confirmInProgress: false, confirmError: payload };

    default:
      return state;
  }
}

// ================ Selectors ================ //

export const authenticationInProgress = state => {
  const { loginInProgress, logoutInProgress, signupInProgress } = state.auth;
  return loginInProgress || logoutInProgress || signupInProgress;
};

// ================ Action creators ================ //

export const authInfoRequest = () => ({ type: AUTH_INFO_REQUEST });
export const authInfoSuccess = info => ({ type: AUTH_INFO_SUCCESS, payload: info });

export const loginRequest = () => ({ type: LOGIN_REQUEST });
export const loginSuccess = () => ({ type: LOGIN_SUCCESS });
export const loginError = error => ({ type: LOGIN_ERROR, payload: error, error: true });

export const logoutRequest = () => ({ type: LOGOUT_REQUEST });
export const logoutSuccess = () => ({ type: LOGOUT_SUCCESS });
export const logoutError = error => ({ type: LOGOUT_ERROR, payload: error, error: true });

export const signupRequest = () => ({ type: SIGNUP_REQUEST });
export const signupSuccess = () => ({ type: SIGNUP_SUCCESS });
export const signupError = error => ({ type: SIGNUP_ERROR, payload: error, error: true });

export const confirmRequest = () => ({ type: CONFIRM_REQUEST });
export const confirmSuccess = () => ({ type: CONFIRM_SUCCESS });
export const confirmError = error => ({ type: CONFIRM_ERROR, payload: error, error: true });

export const userLogout = () => ({ type: USER_LOGOUT });

//  const body = {
//    firstName: currentUser?.attributes?.profile.firstName, // Customer's first name
//    lastName: currentUser?.attributes?.profile.lastName, // Customer's last name
//    email: currentUser?.attributes?.email, // Customer's email address
//    phone: {
//      country_code: '20', // Country code for the phone number
//      number: '10000000', // The phone number
//    },
//  };

//  const data = await onCreateCustomer(body);

// ================ Thunks ================ //

export const authInfo = () => (dispatch, getState, sdk) => {
  dispatch(authInfoRequest());
  return sdk
    .authInfo()
    .then(info => dispatch(authInfoSuccess(info)))
    .catch(e => {
      // Requesting auth info just reads the token from the token
      // store (i.e. cookies), and should not fail in normal
      // circumstances. If it fails, it's due to a programming
      // error. In that case we mark the operation done and dispatch
      // `null` success action that marks the user as unauthenticated.
      log.error(e, 'auth-info-failed');
      dispatch(authInfoSuccess(null));
    });
};

// const body = {
//   firstName: currentUser?.attributes?.profile.firstName, // Customer's first name
//   lastName: currentUser?.attributes?.profile.lastName, // Customer's last name
//   email: currentUser?.attributes?.email, // Customer's email address
//   phone: {
//     country_code: '20', // Country code for the phone number
//     number: '10000000', // The phone number
//   },
// };

// const data = await onCreateCustomer(body);

export const login = (username, password) => (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(loginRequest());

  // Note that the thunk does not reject when the login fails, it
  // just dispatches the login error action.
  return sdk
    .login({ username, password })
    .then(() => dispatch(loginSuccess()))
    .then(() => {
      dispatch(fetchCurrentUser()).then(user => {
        const { publicData, } = user?.attributes?.profile || {};
        const { userType } = publicData || {};
        const userId = user.id.uuid;
        if (userType == 'customer') {
          mixpanel.track(mixPanelEventsConstants.USER_SIGNED_IN, {
            screenName: mixPanelEventsScreens.SIGNUP,
            language: 'en',
            userType: 'Customer',
            userID: userId
          });
        } else if (userType == 'provider') {

          mixpanel.track(mixPanelEventsConstants.USER_SIGNED_IN, {
            screenName: mixPanelEventsScreens.SIGNUP,
            language: 'en',
            userType: 'Provider',
            userID: userId
          });
        }

      });
    })
    .catch(e => dispatch(loginError(storableError(e))));
};

export const logout = () => (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(logoutRequest());

  // Note that the thunk does not reject when the logout fails, it
  // just dispatches the logout error action.
  return sdk
    .logout()
    .then(() => {
      // The order of the dispatched actions
      dispatch(logoutSuccess());
      dispatch(clearCurrentUser());
      log.clearUserId();
      dispatch(userLogout());
    })
    .catch(e => dispatch(logoutError(storableError(e))));
};

export const signup = params => (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(signupRequest());

  const { phoneNumber } = params?.protectedData || {};

  return new Promise(async (resolve, reject) => {
    if (!phoneNumber) {
      return resolve(); // No phone number, proceed with signup
    }

    // Check if phone number exists
    const res = await onCheckPhoneNumberExists({ phoneNumber });
    const isPhoneNumberExist = res?.phoneNumberExists;

    if (isPhoneNumberExist) {
      // Reject promise with error if phone number exists
      return reject({ message: "phone-taken" });
    } else {
      return resolve(); // Proceed if phone number does not exist
    }
  })
    .then(() => {
      // Create user since phone number is unique
      return sdk.currentUser.create(params);
    })
    .then((user) => {
      const userId = user?.data?.data?.id?.uuid;

      // Track user creation with Mixpanel
      if (params.publicData.userType === 'customer') {
        mixpanel.people.set_once({
          $name: `${params.firstName} ${params.lastName}`,
          $email: params.email,
          $first_name: params.firstName,
          $last_name: params.lastName,
          userType: 'Customer',
          userID: userId,
          distinct_id: userId,
        });
        mixpanel.identify(userId);
        mixpanel.track(mixPanelEventsConstants.USER_SIGNED_UP, {
          signup_method: 'email',
          language: 'en',
          step: mixPanelEventsData.SIGN_UP,
          screenName: mixPanelEventsScreens.SIGNUP,
          userType: 'Customer',
          userID: userId,
        });
      } else if (params.publicData.userType === 'provider') {
        mixpanel.people.set_once({
          $name: `${params.firstName} ${params.lastName}`,
          $email: params.email,
          $first_name: params.firstName,
          $last_name: params.lastName,
          userType: 'Provider',
          userID: userId,
          distinct_id: userId,
        });
        mixpanel.identify(userId);
        mixpanel.track(mixPanelEventsConstants.USER_SIGNED_UP, {
          signup_method: 'email',
          language: 'en',
          step: mixPanelEventsData.SIGN_UP,
          screenName: mixPanelEventsScreens.SIGNUP,
          userType: 'Provider',
          userID: userId,
        });
      }
      dispatch(signupSuccess());
    })
    .then(() => dispatch(login(params.email, params.password)))
    .then(async () => {
      dispatch(fetchCurrentUser());
      const { currentUser } = getState()?.user;

      const { publicData, protectedData, firstName, lastName, email } = currentUser?.attributes?.profile || {};
      const { userType } = publicData || {};
      const { phoneNumber } = protectedData || {};

      // Send verification OTP to merchant to verify their mobile number
      if (userType === 'provider') {
        if (phoneNumber) {
          sendVerificationOtp({ phoneNumber: phoneNumber })
            .then(res => {
              // Handle OTP sending success
            })
            .catch(err => {
              // Handle OTP sending error
            });
        }
      }
    })
    .catch(e => {
      // Catch any errors during signup process
      dispatch(signupError(storableError(e)));
      log.error(e, 'signup-failed', {
        email: params.email,
        firstName: params.firstName,
        lastName: params.lastName,
      });
    });
};

export const signupWithIdp = params => (dispatch, getState, sdk) => {
  dispatch(confirmRequest());

  const { phoneNumber } = params?.protectedData || {};

  return new Promise(async (resolve, reject) => {
    if (!phoneNumber) {
      return resolve(); // No phone number, proceed with signup
    }

    // Check if phone number exists
    const res = await onCheckPhoneNumberExists({ phoneNumber });
    const isPhoneNumberExist = res?.phoneNumberExists;

    if (isPhoneNumberExist) {
      // Reject promise with error if phone number exists
      return reject({ message: "phone-taken" });
    } else {
      return resolve(); // Proceed if phone number does not exist
    }
  })
    .then(() => {
      // Proceed with creating the user after phone number validation
      return createUserWithIdp(params);
    })
    .then(res => {
      const { currentUser } = getState()?.user;
      const { publicData, protectedData, firstName, lastName, email } = currentUser?.attributes?.profile || {};
      const { userType } = publicData || {};
      const { phoneNumber } = protectedData || {};
      const userId = currentUser?.id?.uuid;

      // Send verification OTP to merchant to verify their mobile number
      if (userType === 'provider') {
        if (phoneNumber) {
          sendVerificationOtp({ phoneNumber: phoneNumber })
            .then(res => {
              // Handle OTP sending success
            })
            .catch(err => {
              // Handle OTP sending error
            });
        }
      }

      if (userType == 'customer') {
        mixpanel.people.set_once({
          $name: `${firstName} ${lastName}`,
          $email: email,
          $first_name: firstName,
          $last_name: lastName,
          userType: 'Customer',
          userID: userId,
          distinct_id: userId,
        });
        mixpanel.identify(userId);
        mixpanel.track(mixPanelEventsConstants.USER_SIGNED_UP, {
          signup_method: 'GOOGLE',
          language: 'en',
          step: mixPanelEventsData.SIGN_UP,
          screenName: mixPanelEventsScreens.SIGNUP,
          userType: 'Customer',
          userID: userId,
        });
      } else if (userType == 'provider') {
        mixpanel.people.set_once({
          $name: `${firstName} ${lastName}`,
          $email: email,
          $first_name: firstName,
          $last_name: lastName,
          userType: 'Provider',
          userID: userId,
          distinct_id: userId,
        });
        mixpanel.identify(userId);
        mixpanel.track(mixPanelEventsConstants.USER_SIGNED_UP, {
          signup_method: 'GOOGLE',
          language: 'en',
          step: mixPanelEventsData.SIGN_UP,
          screenName: mixPanelEventsScreens.SIGNUP,
          userType: 'Provider',
          userID: userId,
        });
      }

      return dispatch(confirmSuccess());
    })
    .then(() => dispatch(fetchCurrentUser()))
    .catch(e => {
      log.error(e, 'create-user-with-idp-failed', { params });
      return dispatch(confirmError(storableError(e)));
    });
};

