import reverse from 'lodash/reverse';
import sortBy from 'lodash/sortBy';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import { getAllTransitionsForEveryProcess } from '../../transactions/transaction';
import { storableError } from '../../util/errors';
import { parse } from '../../util/urlHelpers';

// Action types for fetching orders
export const FETCH_ORDERS_REQUEST = 'app/CalendarPage/FETCH_ORDERS_REQUEST';
export const FETCH_ORDERS_SUCCESS = 'app/CalendarPage/FETCH_ORDERS_SUCCESS';
export const FETCH_ORDERS_ERROR = 'app/CalendarPage/FETCH_ORDERS_ERROR';

/**
 * Sort transactions by their last transitioned date in descending order.
 * @param {Array} transactions - The list of transactions to sort.
 * @returns {Array} - Sorted transactions.
 */
const sortTransactions = transactions =>
  reverse(
    sortBy(transactions, transaction => {
      return transaction.attributes ? transaction.attributes.lastTransitionedAt : null;
    })
  );

/**
 * Map entity objects to their references.
 * @param {Array} entities - The list of entities to map.
 * @returns {Array} - Mapped entity references.
 */
const mapEntityRefs = entities =>
  entities.map(entity => ({
    id: entity.id,
    type: entity.type,
  }));

const initialState = {
  fetchInProgress: false,
  fetchOrdersOrSalesError: null,
  pagination: null,
  transactionRefs: [],
};

/**
 * Reducer function for managing the state of the order page.
 * @param {Object} state - Current state of the order page.
 * @param {Object} action - Action dispatched to the reducer.
 * @returns {Object} - New state after applying the action.
 */
export default function orderPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case FETCH_ORDERS_REQUEST:
      return { ...state, fetchInProgress: true, fetchOrdersOrSalesError: null };
    case FETCH_ORDERS_SUCCESS: {
      const sortedTransactionsList = sortTransactions(payload.data.data);
      return {
        ...state,
        fetchInProgress: false,
        transactionRefs: mapEntityRefs(sortedTransactionsList),
        pagination: payload.data.meta,
      };
    }
    case FETCH_ORDERS_ERROR:
      console.error(payload); // eslint-disable-line
      return { ...state, fetchInProgress: false, fetchOrdersOrSalesError: payload };

    default:
      return state;
  }
}

/**
 * Action creator for request to fetch orders or sales.
 * @returns {Object} - Action object.
 */
const fetchOrdersOrSalesRequest = () => ({ type: FETCH_ORDERS_REQUEST });

/**
 * Action creator for successful fetching of orders or sales.
 * @param {Object} response - Response data from the API.
 * @returns {Object} - Action object.
 */
const fetchOrdersOrSalesSuccess = response => ({
  type: FETCH_ORDERS_SUCCESS,
  payload: response,
});

/**
 * Action creator for error while fetching orders or sales.
 * @param {Error} error - Error object.
 * @returns {Object} - Action object.
 */
const fetchOrdersOrSalesError = error => ({
  type: FETCH_ORDERS_ERROR,
  error: true,
  payload: error,
});

const INBOX_PAGE_SIZE = 10;

/**
 * Load order or sale data based on parameters and search query.
 * @param {Object} params - Query parameters for the API.
 * @param {string} search - Search string.
 * @returns {Function} - Thunk function to perform the asynchronous action.
 */
export const loadData = (params, search) => (dispatch, getState, sdk) => {
  dispatch(fetchOrdersOrSalesRequest());
  const { page = 1 } = parse(search);

  // Prepare API query parameters
  const apiQueryParams = {
    only: 'sale',
    lastTransitions: getAllTransitionsForEveryProcess(),
    processNames:['default-booking', 'default-deal-booking'] ,
    include: [
      'listing',
      'provider',
      'provider.profileImage',
      'customer',
      'customer.profileImage',
      'booking',
    ],
    'fields.transaction': [
      'processName',
      'lastTransition',
      'lastTransitionedAt',
      'transitions',
      'payinTotal',
      'payoutTotal',
      'lineItems',
      'metadata',
      'protectedData'
    ],
    'fields.listing': ['title', 'availabilityPlan', 'publicData.listingType'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': ['variants.square-small', 'variants.square-small2x'],
    page,
    perPage: INBOX_PAGE_SIZE,
  };

  // Fetch data from the API
  return sdk.transactions
    .query(apiQueryParams)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(fetchOrdersOrSalesSuccess(response));
      return response;
    })
    .catch(error => {
      dispatch(fetchOrdersOrSalesError(storableError(error)));
      throw error;
    });
};
