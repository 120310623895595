import React from 'react';

// Import config and utils
import {
  SCHEMA_TYPE_ENUM,
  SCHEMA_TYPE_MULTI_ENUM,
  SCHEMA_TYPE_TEXT,
  SCHEMA_TYPE_LONG,
  SCHEMA_TYPE_BOOLEAN,
  SCHEMA_TYPE_DATE,
  SCHEMA_TYPE_PHONE,
  SCHEMA_TYPE_EMAIL,
  SCHEMA_TYPE_SINGLE_IMAGE_UPLOAD,
  SCHEMA_TYPE_DOB,
} from '../../util/types';
import { useIntl, FormattedMessage } from '../../util/reactIntl';
import {
  required,
  nonEmptyArray,
  validateInteger,
  composeValidators,
  emailFormatValid,
} from '../../util/validators';
// Import shared components
import {
  FieldCheckboxGroup,
  FieldSelect,
  FieldTextInput,
  FieldBoolean,
  FieldPhoneNumberInput,
} from '..';
// Import modules from this directory
import css from './CustomExtendedDataField.module.css';
import { isUploadImageOverLimitError } from '../../util/errors';
import { Field } from 'react-final-form';
import FieldCheckboxComponent from '../FieldCheckbox/FieldCheckbox';

const createFilterOptions = options => options.map(o => ({ key: `${o.option}`, label: o.label }));

const getLabel = fieldConfig => fieldConfig?.saveConfig?.label || fieldConfig?.label;

const CustomFieldEnum = props => {
  const { name, fieldConfig, defaultRequiredMessage, formId, intl, label: explicitLabel, placeholder: explicitPlaceholder } = props;
  const { enumOptions = [], saveConfig } = fieldConfig || {};
  const { placeholderMessage, isRequired, requiredMessage } = saveConfig || {};
  const validateMaybe = isRequired
    ? { validate: required(intl.formatMessage({ id: `enum.requiredMessage.${name}` }) || defaultRequiredMessage) }
    : {};

  const placeholder = explicitPlaceholder ||
    intl.formatMessage({ id: `enum.placeholder.${name}` }) ||
    intl.formatMessage({ id: 'CustomExtendedDataField.placeholderSingleSelect' });
  const filterOptions = createFilterOptions(enumOptions);

  const label = explicitLabel || getLabel(fieldConfig);

  return filterOptions ? (
    <FieldSelect
      className={css.customField}
      name={name}
      id={formId ? `${formId}.${name}` : name}
      // label={label}
      label={<FormattedMessage id={`enum.label.${name}`} />}
      {...validateMaybe}
    >
      <option disabled value="">
        {fieldConfig.key === "serviceDuration" ? intl.formatMessage({ id: 'CustomExtendedDataField.duration' }) : placeholder}
      </option>
      {filterOptions.map(optionConfig => {
        const key = optionConfig.key;
        const formatted = intl.formatMessage({ id: `enum.${key}` });
        return (
          <option key={key} value={key}>
            {formatted}
          </option>
        );
      })}
    </FieldSelect>
  ) : null;
};

const CustomFieldMultiEnum = props => {
  const { name, fieldConfig, defaultRequiredMessage, formId } = props;
  const { enumOptions = [], saveConfig } = fieldConfig || {};
  const { isRequired, requiredMessage } = saveConfig || {};
  const label = getLabel(fieldConfig);
  const validateMaybe = isRequired
    ? { validate: nonEmptyArray(requiredMessage || defaultRequiredMessage) }
    : {};

  return enumOptions ? (
    <FieldCheckboxGroup
      className={css.customField}
      id={formId ? `${formId}.${name}` : name}
      name={name}
      // label={label}
      label={<FormattedMessage id={`enum.label.${name}`} />}
      options={createFilterOptions(enumOptions)}
      {...validateMaybe}
    />
  ) : null;
};

const CustomFieldText = props => {
  const { name, fieldConfig, defaultRequiredMessage, formId, intl } = props;
  const { placeholderMessage, isRequired, requiredMessage } = fieldConfig?.saveConfig || {};
  const label = getLabel(fieldConfig);
  const validateMaybe = isRequired
    ? { validate: required(requiredMessage || defaultRequiredMessage) }
    : {};
  const placeholder =
    placeholderMessage || intl.formatMessage({ id: 'CustomExtendedDataField.placeholderText' });

  return (
    <FieldTextInput
      className={css.customField}
      id={formId ? `${formId}.${name}` : name}
      name={name}
      type="textarea"
      // label={label}
      label={<FormattedMessage id={`enum.label.${name}`} />}
      placeholder={placeholder}
      {...validateMaybe}
    />
  );
};

/**
 * A custom date input field component.
 *
 * @param {Object} props - The properties passed to the component.
 *
 * @returns {JSX.Element} The rendered date input field.
 *
 * @description
 * This component renders a text input field of type "date". It uses the `FieldTextInput` component
 * with additional configurations such as validation and placeholder text. The placeholder text is
 * either taken from the `fieldConfig.saveConfig.placeholderMessage` or from an internationalization
 * message. If the field is marked as required, validation is added to ensure the field is not left empty.
 */

const CustomFieldDate = props => {
  const { name, fieldConfig, defaultRequiredMessage, formId, intl } = props;
  const { placeholderMessage, isRequired, requiredMessage } = fieldConfig?.saveConfig || {};
  const label = getLabel(fieldConfig);
  const validateMaybe = isRequired
    ? { validate: required(requiredMessage || defaultRequiredMessage) }
    : {};
  const placeholder =
    placeholderMessage || intl.formatMessage({ id: 'CustomExtendedDataField.placeholderText' });

  return (
    <FieldTextInput
      className={css.customField}
      id={formId ? `${formId}.${name}` : name}
      name={name}
      type="date"
      label={label}
      placeholder={placeholder}
      {...validateMaybe}
    />
  );
};

const CustomFieldLong = props => {
  const { name, fieldConfig, defaultRequiredMessage, formId, intl } = props;
  const { minimum, maximum, saveConfig } = fieldConfig;
  const { placeholderMessage, isRequired, requiredMessage } = saveConfig || {};
  const label = getLabel(fieldConfig);
  const placeholder =
    placeholderMessage || intl.formatMessage({ id: 'CustomExtendedDataField.placeholderLong' });
  const numberTooSmallMessage = intl.formatMessage(
    { id: 'CustomExtendedDataField.numberTooSmall' },
    { min: minimum }
  );
  const numberTooBigMessage = intl.formatMessage(
    { id: 'CustomExtendedDataField.numberTooBig' },
    { max: maximum }
  );

  // Field with schema type 'long' will always be validated against min & max
  const validate = (value, min, max) => {
    const requiredMsg = requiredMessage || defaultRequiredMessage;
    return isRequired && value == null
      ? requiredMsg
      : validateInteger(value, max, min, numberTooSmallMessage, numberTooBigMessage);
  };

  return (
    <FieldTextInput
      className={css.customField}
      id={formId ? `${formId}.${name}` : name}
      name={name}
      type="number"
      step="1"
      parse={value => {
        const parsed = Number.parseInt(value, 10);
        return Number.isNaN(parsed) ? null : parsed;
      }}
      label={label}
      placeholder={intl.formatMessage({ id: `long.placeholder.${name}` })}
      validate={value => validate(value, minimum, maximum)}
    />
  );
};
//boolean field
const CustomFieldBoolean = props => {
  const { name, fieldConfig, defaultRequiredMessage, formId, intl } = props;
  const { placeholderMessage, isRequired, requiredMessage } = fieldConfig?.saveConfig || {};
  const label = getLabel(fieldConfig);
  const validateMaybe = isRequired
    ? { validate: required(requiredMessage || defaultRequiredMessage) }
    : {};
  const placeholder =
    placeholderMessage || intl.formatMessage({ id: 'CustomExtendedDataField.placeholderBoolean' });

  return (
    fieldConfig.key === "is_home_service_available" ?
      <FieldCheckboxComponent
        className={css.homeServiceCheckbox}
        id={formId ? `${formId}.${name}` : name}
        name={name}
        label={<FormattedMessage id={`boolean.label.${name}`} />}
        {...validateMaybe}
      />
      :
      <FieldBoolean
      className={css.customField}
      id={formId ? `${formId}.${name}` : name}
      name={name}
      // label={label}
      label={<FormattedMessage id={`boolean.label.${name}`} />}
      // placeholder={placeholder}
      {...validateMaybe}
    />
  );
};
// phone_number
const CustomFieldPhone = props => {
  const { name, fieldConfig, defaultRequiredMessage, formId, intl } = props;
  const { isRequired, placeholderMessage, requiredMessage } = fieldConfig?.saveConfig || {};
  const label = getLabel(fieldConfig);
  const validateMaybe = isRequired
    ? { validate: required(requiredMessage || defaultRequiredMessage) }
    : {};
  const placeholder =
    placeholderMessage ||
    intl.formatMessage({
      id: 'CustomExtendedDataField.placeholderPhone',
    });

  return (
    <FieldPhoneNumberInput
      id={formId ? `${formId}.${name}` : name}
      name={name}
      label={label}
      placeholder={placeholder}
      {...validateMaybe}
    />
  );
};

// email
const CustomFieldEmail = props => {
  const { name, fieldConfig, formId, intl } = props;
  const { schemaType, saveConfig } = fieldConfig;
  const { isRequired, placeholderMessage, requiredMessage } = saveConfig || {};
  const label = getLabel(fieldConfig);
  const emailRequiredMessage = intl.formatMessage({
    id: 'LoginForm.emailRequired',
  });
  const emailRequired = required(requiredMessage || emailRequiredMessage);
  const emailInvalidMessage = intl.formatMessage({
    id: 'LoginForm.emailInvalid',
  });
  const emailValid = emailFormatValid(emailInvalidMessage);

  const validateMaybe = isRequired
    ? { validate: composeValidators(emailRequired, emailValid) }
    : {};
  const placeholder =
    placeholderMessage || intl.formatMessage({ id: 'CustomExtendedDataField.placeholderEmail' });

  return (
    <FieldTextInput
      type={schemaType}
      className={css.customField}
      id={formId ? `${formId}.${name}` : name}
      name={name}
      label={label}
      placeholder={placeholder}
      {...validateMaybe}
    />
  );
};
//Logo
const CustomFieldLogo = props => {
  const {
    name,
    fieldConfig,
    formId,
    intl,
    uploadImageError,
    onImageUpload,
    uploadInProgress,
    form,
  } = props;
  const ACCEPT_IMAGES = 'image/*';
  const { schemaType, saveConfig } = fieldConfig;
  const { isRequired, placeholderMessage } = saveConfig || {};
  const label = getLabel(fieldConfig);
  const placeholder =
    placeholderMessage ||
    intl.formatMessage({
      id: 'CustomExtendedDataField.placeholderLogo',
    });
  return (
    <Field
      accept={ACCEPT_IMAGES}
      id={formId ? `${formId}.${name}` : name}
      name={name}
      label={label}
      placeholder={placeholder}
      type="file"
      form={null}
      uploadImageError={uploadImageError}
      disabled={uploadInProgress}
    >
      {fieldProps => {
        const { accept, id, input, label, disabled, uploadImageError } = fieldProps;
        const { name, type } = input;
        const onChange = e => {
          const file = e.target.files[0];
          form.change(name, file);
          form.blur(name);
          if (file != null) {
            const tempId = `${file.name}_${Date.now()}`;
            onImageUpload({ id: tempId, file });
          }
        };

        let error = null;

        if (isUploadImageOverLimitError(uploadImageError)) {
          error = (
            <div className={css.error}>
              <FormattedMessage id="ProfileSettingsForm.imageUploadFailedFileTooLarge" />
            </div>
          );
        } else if (uploadImageError) {
          error = (
            <div className={css.error}>
              <FormattedMessage id="ProfileSettingsForm.imageUploadFailed" />
            </div>
          );
        }

        return (
          <div className={css.uploadAvatarWrapper}>
            <label className={css.label} htmlFor={id}>
              {label}
            </label>
            <input
              accept={accept}
              id={id}
              name={name}
              className={css.uploadAvatarInput}
              disabled={disabled}
              onChange={onChange}
              type={type}
            />
            {error}
          </div>
        );
      }}
    </Field>
  );
};

const CustomFieldDOB = props => {
  const { name, fieldConfig, defaultRequiredMessage, formId, intl } = props;
  const { placeholderMessage, isRequired, requiredMessage } = fieldConfig?.saveConfig || {};
  const label = getLabel(fieldConfig);
  const validateMaybe = isRequired
    ? { validate: required(requiredMessage || defaultRequiredMessage) }
    : {};
  const placeholder =
    placeholderMessage || intl.formatMessage({ id: 'CustomExtendedDataField.placeholderText' });

  return (
    <FieldTextInput
      className={css.customField}
      id={formId ? `${formId}.${name}` : name}
      name={name}
      type="date"
      // label={label}
      label={<FormattedMessage id={`dob.label.${name}`} />}
      placeholder={placeholder}
      {...validateMaybe}
    />
  );
};
/**
 * Return Final Form field for each configuration according to schema type.
 *
 * These custom extended data fields are for generating input fields from configuration defined
 * in marketplace-custom-config.js. Other panels in EditListingWizard might add more extended data
 * fields (e.g. shipping fee), but these are independently customizable.
 *
 * @param {Object} props should contain fieldConfig that defines schemaType, enumOptions?, and
 * saveConfig for the field.
 */
const CustomExtendedDataField = props => {
  const intl = useIntl();
  const { enumOptions = [], schemaType } = props?.fieldConfig || {};

  const renderFieldComponent = (FieldComponent, props) => <FieldComponent {...props} intl={intl} />;

  return schemaType === SCHEMA_TYPE_ENUM && enumOptions
    ? renderFieldComponent(CustomFieldEnum, props)
    : schemaType === SCHEMA_TYPE_MULTI_ENUM && enumOptions
      ? renderFieldComponent(CustomFieldMultiEnum, props)
      : schemaType === SCHEMA_TYPE_TEXT
        ? renderFieldComponent(CustomFieldText, props)
        : schemaType === SCHEMA_TYPE_LONG
          ? renderFieldComponent(CustomFieldLong, props)
          : schemaType === SCHEMA_TYPE_BOOLEAN
            ? renderFieldComponent(CustomFieldBoolean, props)
            : schemaType === SCHEMA_TYPE_DATE
              ? renderFieldComponent(CustomFieldDate, props)
              : schemaType === SCHEMA_TYPE_PHONE
                ? renderFieldComponent(CustomFieldPhone, props)
                : schemaType === SCHEMA_TYPE_EMAIL
                  ? renderFieldComponent(CustomFieldEmail, props)
                  : schemaType === SCHEMA_TYPE_SINGLE_IMAGE_UPLOAD
                    ? renderFieldComponent(CustomFieldLogo, props)
                    : schemaType === SCHEMA_TYPE_DOB
                      ? renderFieldComponent(CustomFieldDOB, props)
                      : null;
};

export default CustomExtendedDataField;
