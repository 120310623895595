// These helpers are calling this template's own server-side routes
// so, they are not directly calling Marketplace API or Integration API.
// You can find these api endpoints from 'server/api/...' directory

import appSettings from '../config/settings';
import { types as sdkTypes, transit } from './sdkLoader';
import Decimal from 'decimal.js';

export const apiBaseUrl = marketplaceRootURL => {
  const port = process.env.REACT_APP_DEV_API_SERVER_PORT;
  const useDevApiServer = process.env.NODE_ENV === 'development' && !!port;

  // In development, the dev API server is running in a different port
  if (useDevApiServer) {
    return `http://localhost:${port}`;
  }

  // Otherwise, use the given marketplaceRootURL parameter or the same domain and port as the frontend
  return marketplaceRootURL
    ? marketplaceRootURL.replace(/\/$/, '')
    : `${typeof window !== 'undefined' ? window.location.origin : ' '}`;
};

// Application type handlers for JS SDK.
//
// NOTE: keep in sync with `typeHandlers` in `server/api-util/sdk.js`
export const typeHandlers = [
  // Use Decimal type instead of SDK's BigDecimal.
  {
    type: sdkTypes.BigDecimal,
    customType: Decimal,
    writer: v => new sdkTypes.BigDecimal(v.toString()),
    reader: v => new Decimal(v.value),
  },
];

const serialize = data => {
  return transit.write(data, { typeHandlers, verbose: appSettings.sdk.transitVerbose });
};

const deserialize = str => {
  return transit.read(str, { typeHandlers });
};

const methods = {
  POST: 'POST',
  GET: 'GET',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
};

// If server/api returns data from SDK, you should set Content-Type to 'application/transit+json'
const request = (path, options = {}) => {
  if (typeof window === 'undefined') return Promise.resolve({});
  const url = `${apiBaseUrl()}${path}`;
  const { credentials, headers, body, ...rest } = options;

  // If headers are not set, we assume that the body should be serialized as transit format.
  const shouldSerializeBody =
    (!headers || headers['Content-Type'] === 'application/transit+json') && body;
  const bodyMaybe = shouldSerializeBody ? { body: serialize(body) } : {};

  const fetchOptions = {
    credentials: credentials || 'include',
    // Since server/api mostly talks to Marketplace API using SDK,
    // we default to 'application/transit+json' as content type (as SDK uses transit).
    headers: headers
      ? { ...headers, 'x-platform': 'web' }
      : { 'Content-Type': 'application/transit+json', 'x-platform': 'web' },
    ...bodyMaybe,
    ...rest,
  };

  return window.fetch(url, fetchOptions).then(res => {
    const contentTypeHeader = res.headers.get('Content-Type');
    const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;

    if (res.status >= 400) {
      return res.json().then(data => {
        let e = new Error();
        e = Object.assign(e, data);

        throw e;
      });
    }
    if (contentType === 'application/transit+json') {
      return res.text().then(deserialize);
    } else if (contentType === 'application/json') {
      return res.json();
    }
    return res.text();
  });
};

// Keep the previous parameter order for the post method.
// For now, only POST has own specific function, but you can create more or use request directly.
const post = (path, body, options = {}) => {
  const requestOptions = {
    ...options,
    method: methods.POST,
    body,
  };

  return request(path, requestOptions);
};

const get = (path, body, options = {}) => {
  const requestOptions = {
    ...options,
    method: methods.GET,
  };

  return request(path, requestOptions);
};

// Fetch transaction line items from the local API endpoint.
//
// See `server/api/transaction-line-items.js` to see what data should
// be sent in the body.
export const transactionLineItems = body => {
  return post('/api/transaction-line-items', body);
};

// Initiate a privileged transaction.
//
// With privileged transitions, the transactions need to be created
// from the backend. This endpoint enables sending the order data to
// the local backend, and passing that to the Marketplace API.
//
// See `server/api/initiate-privileged.js` to see what data should be
// sent in the body.
export const initiatePrivileged = body => {
  return post('/api/initiate-privileged', body);
};

// Transition a transaction with a privileged transition.
//
// This is similar to the `initiatePrivileged` above. It will use the
// backend for the transition. The backend endpoint will add the
// payment line items to the transition params.
//
// See `server/api/transition-privileged.js` to see what data should
// be sent in the body.
export const transitionPrivileged = body => {
  return post('/api/transition-privileged', body);
};

// Create user with identity provider (e.g. Facebook or Google)
//
// If loginWithIdp api call fails and user can't authenticate to Marketplace API with idp
// we will show option to create a new user with idp.
// For that user needs to confirm data fetched from the idp.
// After the confirmation, this endpoint is called to create a new user with confirmed data.
//
// See `server/api/auth/createUserWithIdp.js` to see what data should
// be sent in the body.
export const createUserWithIdp = body => {
  return post('/api/auth/create-user-with-idp', body);
};

//algolia-search api's.

/*
Sends a POST request to the /api/algolia/createObject endpoint, which likely creates a new object in an Algolia index
*/
export const createAlgoliaObject = body => {
  return post('/api/algolia/createObject', body);
};
/*
Sends a POST request to the /api/algolia/updateObject endpoint, which updates an existing object in an Algolia index.
*/
export const updateAlgoliaObject = body => {
  return post('/api/algolia/updateObject', body);
};
/*
Sends a POST request to the /api/algolia/partialUpdateObject endpoint, which performs a partial update on an object in an Algolia index. This is useful when only certain fields of the object need to be updated without affecting the others.
*/
export const partialUpdateAlgoliaObject = body => {
  return post('/api/algolia/partialUpdateObject', body);
};
/*
Sends a GET request to the /api/algolia/searchData endpoint, which searches the Algolia index and returns matching results based on the search query.
*/
// export const searchAlgoliaData = body => {
//   return post('/api/algolia/searchData', body);
// };

// export const getAlgoliaObject = body => {
//   return post('/api/algolia/getObject', body);
// };

export const onUpdateBusinessProfile = body => {
  return post('/api/update-business-profile', body);
};

export const onGetCategories = () => {
  return get('/api/categories-list');
};

export const onGetDealsCategoriesList = () => {
  return get('/api/deals-categories-list');
};

export const onGetInterestList = () => {
  return get('/api/interests-list');
};

export const onCreatePayment = body => {
  return post('/api/create-payment', body);
};

export const onCreateCharge = body => {
  return post('/api/create-charge', body);
};

export const onCreateLead = body => {
  return post('/api/create-lead', body);
};

export const onCreateMerchant = body => {
  return post('/api/create-merchant', body);
};

export const onCreateBusiness = body => {
  return post('/api/create-business', body);
};

export const onGeneratePaymetStatus = body => {
  return post('/api/payment-status', body);
};

export const onFetchPaymentStatus = charge_id => {
  return post('/api/payment-status?charge_id=' + charge_id);
};

export const onFetchCustomerCards = body => {
  return post('/api/fetch-customer-cards', body);
};

export const onCreateTokenForSavedCard = body => {
  return post('/api/create-token-for-saved-cards', body);
};

// //// twilio endpoints
export const sendVerificationOtp = body => {
  return post('/api/send-otp', body);
};

export const verifyOtp = body => {
  return post('/api/verify-otp', body);
};

// Fetch cart transaction line items from the local API endpoint.
//
// See `server/api/cart-transaction-line-items.js` to see what data should
// be sent in the body.
export const cartTransactionLineItems = body => {
  return post('/api/cart-transaction-line-items', body);
};

export const onUpdateCategoriesProfile = body => {
  return post('/api/update-categories-profile', body);
};

export const onUpdateDealCategoriesProfile = body => {
  return post('/api/update-deal-categories-profile', body);
};

export const onSendPushNotification = body => {
  return post('/api/one-signal-push-notification', body);
};

export const onSendNotification = body => {
  return post('/api/add-notification', body);
};

export const onGetNotifications = body => {
  return post('/api/get-notification-history', body);
};

export const onSendBuzzNotification = body => {
  return post('/api/send-buzz-notification', body);
};

export const onVerifyQRCode = body => {
  return post('/api/verify-qr-code', body);
};

export const onCreateCustomer = body => {
  return post('/api/create-customer', body);
};

// complete your profile mail to merchant
export const completeYourProfile = body => {
  return post('/api/send_mail_to_user', body);
};

export const updatePaymentInDB = body => {
  return post('/api/updatePaymentInDB', body);
};

export const deleteCard = body => {
  return post('/api/deleteCard', body);
};

export const updateServicesAndDeals = body => {
  return post('/api/update-services-and-deals', body);
};

export const addUserPasswordHash = body => {
  return post('/api/add_user_password_hash', body);
};

export const sendWhatsAppMessage = body => {
  return post('/api/send-whatsapp-message', body);
};

export const onWhatsappTransaction = (body) => {
  return post(`/api/whatsapp-transactions`, body);
};

export const onCheckPhoneNumberExists = (body) => {
  return post(`/api/check_phone_number_exists`, body);
};

export const onUpdateUserPasswordHash = (body) => {
  return post(`/api/update_user_passwordHash`, body);
};

export const onFetchUserByEmail = (body) => {
  return post('/api/fetch-user-by-email', body);
};

export const onSendImageMessage = (body) => {
  return post('/api/send-image-message', body);
};

export const onCreateAuthorize = (body) => {
  return post('/api/create-authorize', body);
};

export const onRetrieveAuthorize = (body) => {
  return post('/api/retrieve-authorize', body);
};
export const onUpdateTransactionMetadata = (body) => {
  return post('/api/update-transaction-metadata', body);
};

// ratings and reviews
export const onAddReview = (body) => {
  return post('/api/add_review', body);
};

export const onGetListingReviews = (body) => {
  return post('/api/get_listing_reviews', body);
};

export const onGetUserReviews = (body) => {
  return post('/api/get_user_reviews', body);
};
export const onGetOwnReviews = (body) => {
  return post('/api/get_own_reviews', body);
};

export const onGetStaffReviews = (body) => {
  return post('/api/get_staff_reviews', body);
};
export const onGetDetailedListingRatings = (body) => {
  return post('/api/get_detailed_ratings_for_listing', body);
};
export const onGetDetailedUserRatings = (body) => {
  return post('/api/get_detailed_ratings_for_user', body);
};

// AWS
export const onUploadFileToAWS = (body) => {
  return post('/api/upload-file-to-aws', body);
};

export const onContactUs = (body) => {
  return post('/api/contact-us', body);
};

export const onVerifyCaptcha = (body) => {
  return post('/api/verify-captcha', body);
};

// Analytics 
export const onAddAnalytics = (body) => {
  return post('/api/add-analytics', body);
};

export const onFetchAnalytics = (body) => {
  return post('/api/fetch-analytics-web', body);
};
export const onShowUser = (body) => {
  return post('/api/show-user', body);
};

// export const updateReview = (body) => {
//   return post('/api/updateReview', body)
// }
